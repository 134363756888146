import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DragandDrop from "../../../Component/ImageUploader/DragandDrop";
import { API_GET, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
const Step2 = forwardRef((props, ref) => {
  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [currentType, setcurrentType] = useState("0");
  const [image, setImage] = useState();
  const { t } = useTranslation();
  const [comp_id, setCompId] = useState(0);
  const initial1 = {
    caterer_type: currentType,
    about: "",
    company_id: comp_id,
  };
  const initial2 = {
    caterer_type: currentType,
    company_id: comp_id,
    about: "",
    // image: "",
    truck_name: "",
    address: "",
    truck_cat_id: 0,
    specifications: "",
    zip_code: "",
    // start_time: "",
    // end_time: "",
    description: "",
    size: "",
    electricity_type: "",
    operating_mode: "",
    is_water_required: "",
  };

  const validationSchema1 = Yup.object({
    caterer_type: Yup.string().required("Required"),
    about: Yup.string().required("Required"),
  });
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const validationSchema2 = Yup.object({
    caterer_type: Yup.string().required(t("Login.required")),
    about: Yup.string().required(t("Login.required")),

    // image: Yup.string().required(t("Login.required")),

    truck_name: Yup.string().required(t("Login.required")),

    address: Yup.string()
      .required(t("Login.required"))
      .max(200, t("Login.required") + ": Maximum 200 characters"),

    truck_cat_id: Yup.number()
      .required(t("Login.required"))
      .notOneOf([0], t("Login.required")),

    specifications: Yup.string().required(t("Login.required")),

    zip_code: Yup.string().required(t("Login.required")),
    work_area_radius: Yup.number().required(t("Login.required")),

    // start_time: Yup.string().required(t("Login.required")),
    // end_time: Yup.string().required(t("Login.required")),

    description: Yup.string()
      .required(t("Login.required"))
      .max(200, t("Login.required") + ": Maximum 200 characters"),

    size: Yup.number()
      .required(t("Login.required"))
      .min(0, t("Login.required")),

    electricity_type: Yup.string().required(t("Login.required")),

    operating_mode: Yup.string().required(t("Login.required")),

    is_water_required: Yup.string().required(t("Login.required")),
  });

  useEffect(() => {
    if (!formik.values.company_id) {
      let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
      let companyId = parsedData?.company_id;
      setCompId(companyId);
      formik.setFieldValue("company_id", companyId);
    }
  }, [currentType]);

  const handleCategory = async () => {
    try {
      let res = await API_GET(API_endpoints.gettruckcat);
      setCategories(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCategory();
  }, []);

  const getInitialValues = () => {
    const savedData = JSON.parse(localStorage.getItem("Step2Data"));
    if (savedData) {
      return savedData;
    }
    return currentType == 2 ? initial2 : initial1;
  };

  const getValidationSchema = () => {
    return currentType == 2 ? validationSchema2 : validationSchema1;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getValidationSchema(),
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        let res;
        if (values.id) {
          // ---------------------------Update code---------------------------
          res = await API_POST(API_endpoints.updatecaterer, values);
          if (values.caterer_type === 2) {
            let res2 = await API_POST(API_endpoints?.updateTruck, values);
            if (res2 && res2.r && res2.r.id) {
              const d = new FormData();
              d.append("id", values.preImageID); // Assuming you have preImageID for update
              d.append("image", image);
              let dataRes = await API_POST(API_endpoints.updatetruckimage, d);

              const id = dataRes.r.truck_id;
              const img = dataRes.r.image;
              formik.setFieldValue("id", id);
              const updatedValues = { ...values, id, image: img };
              localStorage.setItem("Step2Data", JSON.stringify(updatedValues));
            }
          }
        } else {
          // --------------- Add code ----------------------
          res = await API_POST(API_endpoints.addcaterer, values);
          if (res && res.r && res.r.id) {
            if (values.caterer_type == 2) {
              // Add truck API
              let res2 = await API_POST(API_endpoints?.addtruck, values);
              if (res2 && res2.r && res2.r.id) {
                const d = new FormData();
                d.append("truck_id", res2?.r?.id);
                d.append("image", image);
                // Add truck image API
                let dataRes = await API_POST(API_endpoints.addtruckimage, d);

                const id = dataRes.r.truck_id;
                const img = dataRes.r.image;
                const imgID = dataRes.r.id;
                formik.setFieldValue("id", id);
                const updatedValues = {
                  ...values,
                  id,
                  image: img,
                  preImageID: imgID,
                };
                localStorage.setItem(
                  "Step2Data",
                  JSON.stringify(updatedValues)
                );
              }
            } else {
              const id = res.r.id;
              formik.setFieldValue("id", id);
              const updatedValues = { ...values, id };
              localStorage.setItem("Step2Data", JSON.stringify(updatedValues));
            }

            let localdata = JSON.parse(localStorage.getItem("Fiesta")) || {};
            localdata.caterer_id = res.r.id;
            localStorage.setItem("Fiesta", JSON.stringify(localdata));
          }
        }

        // Check response status for both add and update
        if (res?.s) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error:", error);
        return false;
      }
    },
  });


  useEffect(() => {
    const fetchStep2Data = async () => {
      const savedData = JSON.parse(localStorage.getItem("Step2Data"));

      if (savedData) {
        setcurrentType(savedData?.caterer_type);
        // setImage(savedData?.image)
        formik.setValues(savedData);
      }
    };

    fetchStep2Data();
  }, []);

  function handleTypeChange(value) {
    setcurrentType(value);
  }

 
  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));
  return (
    <Box>
      <Box
        sx={{
          fontSize: { xs: "25px", fontWeight: "600", padding: "1rem" },
        }}
      >
        {t("becomePartner.step2.title")}
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 3 },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <StarSVG /> {t("becomePartner.step2.types.title")}
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="caterer_type"
              id="caterer_type"
              value={formik.values.caterer_type}
              onChange={(e) => {
                handleTypeChange(e.target.value);
                formik.setFieldValue("caterer_type", e.target.value);
              }}
            >
              <FormControlLabel
                value={"1"}
                name="caterer_type"
                control={<Radio value={"1"} name="caterer_type" />}
                label={`${t("becomePartner.step2.types.type1")}`}
              />
              <FormControlLabel
                value={"2"}
                name="caterer_type"
                control={<Radio value={"2"} name="caterer_type" />}
                label={`${t("becomePartner.step2.types.type2")}`}
              />
              <FormControlLabel
                value={"3"}
                name="caterer_type"
                control={<Radio value={"3"} name="caterer_type" />}
                label={`${t("becomePartner.step2.types.type3")}`}
              />
              <FormControlLabel
                value={"4"}
                name="caterer_type"
                control={<Radio value={"4"} name="caterer_type" />}
                label={`${t("becomePartner.step2.types.type4")}`}
              />
            </RadioGroup>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box py={1}>
              <StarSVG /> {t("becomePartner.step2.yourSelf.title")}
            </Box>

            <TextField
              type="text"
              id="about"
              name="about" // This must match the formik field name exactly
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} // Correct onBlur handler
              value={formik.values.about} // Controlled input
              error={formik.touched.about && Boolean(formik.errors.about)}
              helperText={formik.touched.about && formik.errors.about}
              variant="outlined"
              placeholder={t("becomePartner.step2.yourSelf.pl")}
              multiline
              fullWidth
              rows={5}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box sx={{ display: currentType == 2 ? "block" : "none" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: theme.palette.text.active,
              }}
            >
              {t("becomePartner.step2.foodTruckDetails.title")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />
                {t("becomePartner.step2.foodTruckDetails.image.title")}
              </Box>
              <DragandDrop
                setImage={setImage}
                apiPreview={
                  typeof formik.values.image === "string"
                    ? API_endpoints.baseURL + formik.values.image
                    : null
                }
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: { xs: 2, sm: 2 },
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.truckName.title")}
                </Box>

                <TextField
                  type="text"
                  id="truck_name"
                  name="truck_name"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.truck_name}
                  error={
                    formik.touched.truck_name &&
                    Boolean(formik.errors.truck_name)
                  }
                  helperText={
                    formik.touched.truck_name && formik.errors.truck_name
                  }
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.truckName.pl"
                  )}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />
                  {t("becomePartner.step2.foodTruckDetails.address.title")}
                </Box>

                <TextField
                  type="text"
                  id="address"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.address.pl"
                  )}
                  multiline
                  fullWidth
                  rows={4}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.category.title")}
              </Box>
              <FormControl
                fullWidth
                error={
                  formik.touched.truck_cat_id &&
                  Boolean(formik.errors.truck_cat_id)
                }
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Select
                  defaultValue={0}
                  fullWidth
                  id="truck_cat_id"
                  name="truck_cat_id"
                  value={formik.values.truck_cat_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    color: theme.palette.text.select_input,
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      color: theme.palette.text.defaultDark,
                      display: "none",
                    }}
                    value={0}
                  >
                    {t("becomePartner.step2.foodTruckDetails.category.title")}
                  </MenuItem>

                  {Array.isArray(categories) &&
                    categories?.length > 0 &&
                    categories?.map((e, i) => (
                      <MenuItem
                        style={{ color: theme.palette.text.defaultDark }}
                        key={i}
                        value={e.id}
                      >
                        {e.truck_category}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.truck_cat_id && formik.errors.truck_cat_id && (
                  <FormHelperText>{formik.errors.truck_cat_id}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.specification.title")}
              </Box>
              <TextField
                type="text"
                id="specifications"
                name="specifications"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.specifications}
                error={
                  formik.touched.specifications &&
                  Boolean(formik.errors.specifications)
                }
                helperText={
                  formik.touched.specifications && formik.errors.specifications
                }
                variant="outlined"
                placeholder={t(
                  "becomePartner.step2.foodTruckDetails.specification.pl"
                )}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.zipCode.title")}
              </Box>
              <TextField
                type="number"
                id="zip_code"
                name="zip_code"
                fullWidth
                inputProps={{ min: "0" }} // Prevent negative numbers via UI
                onChange={(e) => {
                  const { value } = e.target;

                  // Prevent negative numbers
                  if (value && Number(value) < 0) {
                    e.target.value = ""; // Clear the input if the value is negative
                    return; // Prevent further processing
                  }

                  // Update formik state
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.zip_code}
                error={
                  formik.touched.zip_code && Boolean(formik.errors.zip_code)
                }
                helperText={formik.touched.zip_code && formik.errors.zip_code}
                variant="outlined"
                placeholder={t(
                  "becomePartner.step2.foodTruckDetails.zipCode.pl"
                )}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.area.title")}
              </Box>
              <TextField
                type="number"
                id="work_area_radius"
                name="work_area_radius"
                fullWidth
                inputProps={{ min: "0" }} // Prevent negative numbers via UI
                onChange={(e) => {
                  const { value } = e.target;

                  // Prevent negative numbers
                  if (value && Number(value) < 0) {
                    e.target.value = ""; // Clear the input if the value is negative
                    return; // Prevent further processing
                  }

                  // Update formik state
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.work_area_radius}
                error={
                  formik.touched.work_area_radius &&
                  Boolean(formik.errors.work_area_radius)
                }
                helperText={
                  formik.touched.work_area_radius &&
                  formik.errors.work_area_radius
                }
                variant="outlined"
                placeholder={t("becomePartner.step2.foodTruckDetails.area.pl")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box py={1}>
              <StarSVG /> {t("becomePartner.step2.foodTruckDetails.desc.title")}
            </Box>

            <TextField
              type="text"
              id="description"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              variant="outlined"
              placeholder={t("becomePartner.step2.foodTruckDetails.desc.pl")}
              multiline
              fullWidth
              rows={4}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: theme.palette.text.active,
                mt: 2,
              }}
            >
              {t("becomePartner.step2.questions.title")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step2.questions.truckSize.title")}
              </Box>
              <TextField
                type="number"
                id="size"
                name="size"
                fullWidth
                inputProps={{ min: "0" }}
                onChange={(e) => {
                  const { value } = e.target;

                  if (value && Number(value) < 0) {
                    e.target.value = "";
                    return;
                  }

                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.size}
                error={formik.touched.size && Boolean(formik.errors.size)}
                helperText={formik.touched.size && formik.errors.size}
                variant="outlined"
                placeholder={t("becomePartner.step2.questions.truckSize.pl")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.questions.electricity.title")}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="electricity_type"
                id="electricity_type"
                row
                value={formik.values.electricity_type}
                onChange={(e) => {
                  formik.setFieldValue("electricity_type", e.target.value);
                }}
              >
                <FormControlLabel
                  value="16A"
                  control={<Radio value="16A" />}
                  label={"16A"}
                />
                <FormControlLabel
                  value="32A"
                  control={<Radio />}
                  label={`32A`}
                />
                <FormControlLabel
                  value="230V"
                  control={<Radio />}
                  label={`230V`}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label={`${t(
                    "becomePartner.step2.questions.electricity.other"
                  )}`}
                />
              </RadioGroup>
              {formik.touched.electricity_type &&
                formik.errors.electricity_type && (
                  <Typography sx={{ fontSize: "12px" }} color="error">
                    {formik.errors.electricity_type}
                  </Typography>
                )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.questions.operatingMode.title")}{" "}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                row
                value={formik.values.operating_mode}
                onChange={(e) => {
                  formik.setFieldValue("operating_mode", e.target.value);
                }}
                name="operating_mode"
              >
                <FormControlLabel
                  value="KWH"
                  control={<Radio />}
                  label={`${t(
                    "becomePartner.step2.questions.operatingMode.op1"
                  )}`}
                />
                <FormControlLabel
                  value="GAS"
                  control={<Radio />}
                  label={`${t(
                    "becomePartner.step2.questions.operatingMode.op2"
                  )}`}
                />
              </RadioGroup>
              {formik.touched.operating_mode &&
                formik.errors.operating_mode && (
                  <Typography sx={{ fontSize: "12px" }} color="error">
                    {formik.errors.operating_mode}
                  </Typography>
                )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step2.questions.water.title")}{" "}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="female"
                row
                value={formik.values.is_water_required}
                onChange={formik.handleChange}
                name="is_water_required"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={`${t("becomePartner.step2.questions.water.op1")}`}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={`${t("becomePartner.step2.questions.water.op2")}`}
                />
              </RadioGroup>
              {formik.touched.is_water_required &&
                formik.errors.is_water_required && (
                  <Typography sx={{ fontSize: "12px" }} color="error">
                    {formik.errors.is_water_required}
                  </Typography>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Step2;
