import { Box, useTheme, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_GET_OPEN, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import ButtonComponent from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import RatingStar from "../../Component/Rating";
import Loader from "../../Component/Loader/Loader";
const Review = () => {
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  let company_id = location.state.company_id;
  const [myRating, setMyRating] = useState([]);
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [loading, setLoading] = useState(false);
  // const [loading1, setLoading1] = useState(false);

  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const navigate = useNavigate();

  const redirect = () => {
    toast.success("Please Login First");
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      review: "",
    },
    validationSchema: Yup.object({
      review: Yup.string()
        .required("Required")
        .min(6, "Required at least 6 digits."),
    }),
    onSubmit: async (values) => {
      const f = new FormData();
      f.append("review", values.review);
      f.append("rating", isClicked);
      f.append("company_id", company_id);
      f.append("user_id", id);
      try {
        setLoading(true);
        const res = await API_POST(API_endpoints.addrating, f);
        if (res.s) {
          setLoading(false);
          toast.success(res.m);
          getRating();
        } else {
          toast.error(res.m);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  const Rating = [
    { id: 1, icon: isClicked >= 1 ? StarRoundedIcon : StarBorderRoundedIcon },
    { id: 2, icon: isClicked >= 2 ? StarRoundedIcon : StarBorderRoundedIcon },
    { id: 3, icon: isClicked >= 3 ? StarRoundedIcon : StarBorderRoundedIcon },
    { id: 4, icon: isClicked >= 4 ? StarRoundedIcon : StarBorderRoundedIcon },
    { id: 5, icon: isClicked === 5 ? StarRoundedIcon : StarBorderRoundedIcon },
  ];

  const getRating = async () => {
    try {
      let url = `${API_endpoints.getrating}?company_id=${company_id}&user_id=${id}`;
      let res = await API_GET_OPEN(url);
      setMyRating(res.r[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRating();
  }, []);

  return (
    <Box
      sx={{
        background: theme.palette.background.main,
        borderRadius: "8px",
        m: 1,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: { xs: "20px", md: "24px", xl: "28px" },
          color: theme.palette.text.brown,
        }}
      >
        My Reviews
      </Typography>
      {myRating?.user_details ? (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                height: { xs: "45px", sm: "60px" },
                width: { xs: "45px", sm: "60px" },
              }}
            >
              <img
                src={
                  API_endpoints.baseURL +
                  "/" +
                  myRating?.user_details?.profile_img
                }
                style={{ height: "100%", width: "100%" }}
                alt=""
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                sx={{
                  color: theme.palette.text.main,
                  fontWeight: "600",
                  fontSize: { xs: "18px", lg: "22px" },
                }}
              >
                {myRating?.user_details?.full_name}
              </Typography>
              {/* <RatingStar rating={myRating?.rating} /> */}
              <RatingStar rating={4} />
            </Box>
            <Box>
              <Typography sx={{ color: `${theme.palette.text.main}90` }}>
                {myRating?.review}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            variant="h6"
            sx={{ color: theme.palette.text.darkOrange }}
          >
            Leave your review
          </Typography>
          <Typography sx={{ color: theme.palette.text.black }}>
            {Rating.map((x, i) => (
              <span key={i} onClick={() => setIsClicked(x.id)}>
                {" "}
                {
                  <x.icon
                    style={{
                      color: theme.palette.text.active,
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                  />
                }
              </span>
            ))}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              pb: { xs: 2, md: 1 },
            }}
          >
            <TextField
              type="text"
              id="review"
              name="review"
              onChange={formik.handleChange}
              value={formik.values.review}
              error={formik.touched.review && Boolean(formik.errors.review)}
              helperText={formik.touched.review && formik.errors.review}
              variant="outlined"
              // placeholder={t("becomePartner.step2.yourSelf.pl")}
              placeholder={t("Write your review here")}
              multiline
              fullWidth
              rows={5}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonComponent
              type="button"
              disabled={loading}
              label={loading ? <Loader /> : "Submit"}
              onClick={() => (id ? formik.handleSubmit() : redirect())}
              sx={{
                width: { xs: "100%", sm: "50%", md: "100%" },
                background: theme.palette.background.submit,
                color: theme.palette.text.darkOrange,
              }}
            />
          </Box>{" "}
        </Box>
      )}
    </Box>
  );
};

export default Review;
