import { Box, useTheme, Typography } from "@mui/material";
import ButtonComponent from "../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import BookNowModal from "./BookNow/BookNowModal";
const DetailsSideBar = ({data}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  
  const databox = [
    {
      title: "Address",
      details: data?.address?.slice(0,150),
    },
    // {
    //   title: " City",
    //   details: "kahabr nathi",
    // },
    {
      title: "Postal Code",
      details: data?.zip_code,
    },
    {
      title: "Size",
      details: data?.size,
    },
    {
      title: "Power",
      details: data?.electricity_type,
    },
  ];
  
  return (
    <Box
      sx={{
        background: theme.palette.background.main,
        borderRadius: "8px",
        m: 1,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "start",
      }}
    >
      {databox?.map((x, i) => (
        <Box key={i} sx={{}}>
          <Typography
            variant="h6"
            sx={{ color: theme.palette.text.darkOrange, fontSize: "16px" }}
          >
            {x.title}
          </Typography>
          <Typography
            sx={{ color: theme.palette.text.black, fontSize: "15px" }}
          >
            {x.details}
          </Typography>
        </Box>
      ))}

      <Box className="flexCenter" sx={{ width: "100%" }}>
        <BookNowModal company_id={data?.company_id} />
      </Box>
      <Box className="flexCenter" sx={{ width: "100%" }}>
        <ButtonComponent
          type="button"
          label={"Send a message"}
          // onClick={()=>}
          sx={{
            width: { xs: "100%", sm: "60%", md: "100%" },
            border: `1px solid ${theme.palette.background.strip}`,
            fontSize: "16px",
            color: theme.palette.text.main,
          }}
        />
      </Box>
    </Box>
  );
};

export default DetailsSideBar;
