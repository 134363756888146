import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useTheme,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import ButtonComponent from "../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import { StarSVG } from "../../Component/SVG";
import { API_GET, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import * as Yup from "yup";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DragandDrop from "../../Component/ImageUploader/DragandDrop";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1400px",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  maxHeight: "calc(100vh - 100px)",
  overflow: "scroll",
};

export default function FoodTruckEditModal({
  setOpen,
  open,
  id,
  getData,
  compID,
}) {
  const handleClose = () => setOpen(false);
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);

  const { t } = useTranslation();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      company_id: "",
      truck_name: "",
      address: "",
      truck_cat_id: 0,
      specifications: "",
      zip_code: "",
      description: "",
      size: "",
      electricity_type: "",
      operating_mode: "",
      is_water_required: "",
    },

    validationSchema: Yup.object({
      //   image: Yup.mixed().required(t("Login.required")),
      truck_name: Yup.string().required(t("Login.required")),

      address: Yup.string()
        .required(t("Login.required"))
        .max(200, t("Login.required") + ": Maximum 200 characters"),

      truck_cat_id: Yup.number()
        .required(t("Login.required"))
        .notOneOf([0], t("Login.required")),

      specifications: Yup.string().required(t("Login.required")),

      zip_code: Yup.string().required(t("Login.required")),
      work_area_radius: Yup.number().required(t("Login.required")),

      description: Yup.string()
        .required(t("Login.required"))
        .max(200, t("Login.required") + ": Maximum 200 characters"),

      size: Yup.number()
        .required(t("Login.required"))
        .min(0, t("Login.required")),

      electricity_type: Yup.string().required(t("Login.required")),

      operating_mode: Yup.string().required(t("Login.required")),

      is_water_required: Yup.string().required(t("Login.required")),
    }),

    onSubmit: async (values) => {
      try
      {
        let res = await API_POST(API_endpoints.edittruck, values);
        if (image.name)
        {
          const f = new FormData();
          f.append("image", image);
          f.append("id", id);

          let ans = await API_POST(API_endpoints.updateTruckImage, f);
        }
        if (res.s)
        {
          toast.success(res.m);
          getData(compID);
          setOpen(false);
          getTruck();
        }
      } catch (error)
      {
        console.log(error);
      }
    },
  });

  const handleCategory = async () => {
    try
    {
      let res = await API_GET(API_endpoints.gettruckcat);
      setCategories(res.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  const getTruck = async () => {
    try
    {
      let res = await API_GET(
        `${API_endpoints.gettruckbyid}?food_truck_id=${id}`
      );
      if (res.s)
      {
        formik.setValues(res.r);
        formik.setFieldValue("image", res?.r?.food_truck_image[0]?.image);
      }
      setImage(res?.r?.food_truck_image[0]?.image);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getTruck();
    handleCategory();
  }, [id]);

  const handleCheckboxChange = (type) => (value) => (event) => {
    const { checked } = event.target;
    const updatedArray = checked
      ? [...formik.values[type], value]
      : formik.values[type].filter((item) => item !== value);

    formik.setFieldValue(type, updatedArray);
  };

  const renderCheckbox = (labelKey, value, type) => (
    <FormControlLabel
      control={
        <Checkbox
          id={labelKey}
          //   checked={formik.values[type].includes(value)}
          onChange={handleCheckboxChange(type)(value)}
          sx={{
            color: theme.palette.text.secondary,
            "&.Mui-checked": {
              color: theme.palette.text.active,
            },
          }}
        />
      }
      label={labelKey}
    />
  );

  const renderMealCheckboxes = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { md: 4 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {[
          { title: "Food Truck", value: 1 },
          { title: "Buffet", value: 2 },
          { title: "Barista", value: 3 },
          { title: "Cocktails", value: 4 },
          { title: "Ice Creams", value: 5 },
          { title: "Sweets", value: 6 },
          { title: "Drinks", value: 7 },
        ].map(({ title, value }) =>
          renderCheckbox(title, value, "catering_Type")
        )}
      </Box>
    </Box>
  );

  const renderDietCheckboxes = () => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {[
        { title: "Vegetarian", value: 1 },
        { title: "Vegan", value: 2 },
        { title: "Gluten - free", value: 3 },
      ].map(({ title, value }) => renderCheckbox(title, value, "diet"))}
    </Box>
  );

  return (
    <>
      {/* <Box className="flexCenter" sx={{ width: "100%" }}>
                <ButtonComponent
                    type="button"
                    label={`${t("Edit")}`}
                    onClick={handleOpen}
                    sx={{
                        width: { xs: "100%", sm: "60%", md: "100%" },
                        background: theme.palette.background.btn,
                        color: theme.palette.text.primary,
                        fontSize: "16px",
                    }}
                />
            </Box> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: { xs: "90%", md: "calc(100% - 50px)" } }}>
          <Box>
            <Box className="flexBetween">
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: { xs: "18px", md: "22px", lg: "26px" },
                  color: theme.palette.text.active,
                }}
              >
                {/* {t("Food Truck Edit")} */}
                Food Truck Edit
              </Typography>
              <ButtonComponent
                type="button"
                label={"Done"}
                // label={`${t("Done")}`}
                onClick={formik.handleSubmit}
                sx={{
                  width: "120px",
                  background: theme.palette.background.btn,
                  color: theme.palette.text.primary,
                  fontSize: "16px",
                  overflow: "hidden",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.image.title")}
                </Box>
                <DragandDrop
                  setImage={setImage}
                  error={!image}
                  helperText={!image && "Required"}
                  apiPreview={
                    typeof formik.values.image === "string"
                      ? API_endpoints.baseURL + formik.values.image
                      : null
                  }
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: 2, sm: 2 },
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.truckName.title")}
                  </Box>

                  <TextField
                    type="text"
                    id="truck_name"
                    name="truck_name"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.truck_name}
                    error={
                      formik.touched.truck_name &&
                      Boolean(formik.errors.truck_name)
                    }
                    helperText={
                      formik.touched.truck_name && formik.errors.truck_name
                    }
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.foodTruckDetails.truckName.pl"
                    )}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.address.title")}
                  </Box>

                  <TextField
                    type="text"
                    id="address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.foodTruckDetails.address.pl"
                    )}
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.category.title")}
                </Box>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.truck_cat_id &&
                    Boolean(formik.errors.truck_cat_id)
                  }
                  sx={{
                    color: theme.palette.text.defaultDark,
                    textAlign: "start",
                    borderRadius: "12px",
                  }}
                >
                  <Select
                    defaultValue={0}
                    fullWidth
                    id="truck_cat_id"
                    name="truck_cat_id"
                    value={formik.values.truck_cat_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      color: theme.palette.text.select_input,
                      borderRadius: "12px",
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                      "&:active": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                    }}
                  >
                    <MenuItem
                      style={{
                        color: theme.palette.text.defaultDark,
                        display: "none",
                      }}
                      value={0}
                    >
                      {t("becomePartner.step2.foodTruckDetails.category.title")}
                    </MenuItem>

                    {Array.isArray(categories) &&
                      categories?.length > 0 &&
                      categories?.map((e, i) => (
                        <MenuItem
                          style={{ color: theme.palette.text.defaultDark }}
                          key={i}
                          value={e.id}
                        >
                          {e.truck_category}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.truck_cat_id &&
                    formik.errors.truck_cat_id && (
                      <FormHelperText>
                        {formik.errors.truck_cat_id}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t(
                    "becomePartner.step2.foodTruckDetails.specification.title"
                  )}
                </Box>
                <TextField
                  type="text"
                  id="specifications"
                  name="specifications"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.specifications}
                  error={
                    formik.touched.specifications &&
                    Boolean(formik.errors.specifications)
                  }
                  helperText={
                    formik.touched.specifications &&
                    formik.errors.specifications
                  }
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.specification.pl"
                  )}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.zipCode.title")}
                </Box>
                <TextField
                  type="number"
                  id="zip_code"
                  name="zip_code"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.zip_code}
                  error={
                    formik.touched.zip_code && Boolean(formik.errors.zip_code)
                  }
                  helperText={formik.touched.zip_code && formik.errors.zip_code}
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.zipCode.pl"
                  )}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.area.title")}
                </Box>
                <TextField
                  type="number"
                  id="work_area_radius"
                  name="work_area_radius"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.work_area_radius}
                  error={
                    formik.touched.work_area_radius &&
                    Boolean(formik.errors.work_area_radius)
                  }
                  helperText={
                    formik.touched.work_area_radius &&
                    formik.errors.work_area_radius
                  }
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.area.pl"
                  )}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.desc.title")}
              </Box>

              <TextField
                type="text"
                id="description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
                placeholder={t("becomePartner.step2.foodTruckDetails.desc.pl")}
                multiline
                fullWidth
                rows={4}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: theme.palette.text.active,
                  mt: 2,
                }}
              >
                {t("becomePartner.step2.questions.title")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.questions.truckSize.title")}
                </Box>
                <TextField
                  type="number"
                  id="size"
                  name="size"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.size}
                  error={formik.touched.size && Boolean(formik.errors.size)}
                  helperText={formik.touched.size && formik.errors.size}
                  variant="outlined"
                  placeholder={t("becomePartner.step2.questions.truckSize.pl")}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.questions.electricity.title")}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="electricity_type"
                  id="electricity_type"
                  row
                  value={formik.values.electricity_type}
                  onChange={(e) => {
                    formik.setFieldValue("electricity_type", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="16A"
                    control={<Radio value="16A" />}
                    label={"16A"}
                  />
                  <FormControlLabel
                    value="32A"
                    control={<Radio />}
                    label={`32A`}
                  />
                  <FormControlLabel
                    value="230V"
                    control={<Radio />}
                    label={`230V`}
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label={`${t(
                      "becomePartner.step2.questions.electricity.other"
                    )}`}
                  />
                </RadioGroup>
                {formik.touched.electricity_type &&
                  formik.errors.electricity_type && (
                    <Typography color="error">
                      {formik.errors.electricity_type}
                    </Typography>
                  )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.questions.operatingMode.title")}{" "}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  row
                  value={formik.values.operating_mode}
                  onChange={(e) => {
                    formik.setFieldValue("operating_mode", e.target.value);
                  }}
                  name="operating_mode"
                >
                  <FormControlLabel
                    value="KWH"
                    control={<Radio />}
                    label={`${t(
                      "becomePartner.step2.questions.operatingMode.op1"
                    )}`}
                  />
                  <FormControlLabel
                    value="GAS"
                    control={<Radio />}
                    label={`${t(
                      "becomePartner.step2.questions.operatingMode.op2"
                    )}`}
                  />
                </RadioGroup>
                {formik.touched.operating_mode &&
                  formik.errors.operating_mode && (
                    <Typography color="error">
                      {formik.errors.operating_mode}
                    </Typography>
                  )}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG /> {t("becomePartner.step2.questions.water.title")}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="female"
                  row
                  value={formik.values.is_water_required}
                  onChange={formik.handleChange}
                  name="is_water_required"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={`${t("becomePartner.step2.questions.water.op1")}`}
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={`${t("becomePartner.step2.questions.water.op2")}`}
                  />
                </RadioGroup>
                {formik.touched.is_water_required &&
                  formik.errors.is_water_required && (
                    <Typography color="error">
                      {formik.errors.is_water_required}
                    </Typography>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
