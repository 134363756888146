import { Box } from "@mui/material";
import Work from "../NotFoundPage/Work";
import ChatListComponent from "./ChatList/ChatListComponent";
import ChatMessageComponent from "./ChatMessage/ChatMessageComponent";
import { useState } from "react";

const ChatScreen = () => {
  const [selectedChatId, setSelectedChatId] = useState(null);
  return (
    <Box
      sx={{
        display: "flex",
        borderTop: "1px solid #0000003D ",
        height: "calc(100vh - 76px)",
        background: "#F5F5F5",
      }}
    >
      {/* user Listing component */}
      <ChatListComponent
        setSelectedChatId={setSelectedChatId}
        selectedChatId={selectedChatId}
      />
      {/* user message handler */}
      <ChatMessageComponent selectedChatId={selectedChatId} />
    </Box>
  );
};

export default ChatScreen;
