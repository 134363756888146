import { Box, Typography } from "@mui/material";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

const ChatHeader = () => {
    let img = "https://via.placeholder.com/40";
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 1,
                p: 1,
                borderBottom: "1px solid #00000033",
                borderRadius: '7px',
                width: '100%',
            }}
        >
            <ChevronLeftRoundedIcon />
            <img
                src={img}
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                alt=""
            />
            <Typography sx={{ fontSize: { xs: '18px', md: '20px' } }}>Test Name</Typography>
        </Box>
    );
};

export default ChatHeader;
