import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Box, TextField, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import { API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const Step1 = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parsedData?.id;
  const formik = useFormik({
    initialValues: {
      company_name: "",
      city: "",
      postal_code: "",
      website_link: "",
      user_id: id,
      id: "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required(
        t("becomePartner.step1.vali.Required")
      ),
      city: Yup.string()
        .trim()
        .required(t("becomePartner.step1.vali.Required")),
      postal_code: Yup.string()
        .trim()
        .required(t("becomePartner.step1.vali.Required")),
      website_link: Yup.string()
        .trim()
        .url(t("becomePartner.step1.vali.InvalidURL"))
        .required(t("becomePartner.step1.vali.Required")),
    }),
    onSubmit: async (values) => {
      try {
        let res;
        if (values.id) {
          res = await API_POST(API_endpoints.updatecompany, values);
        } else {
          res = await API_POST(API_endpoints.addcompany, values);
          if (res && res.r && res.r.id) {
            const id = res.r.id;
            formik.setFieldValue("id", id);
            const updatedValues = { ...values, id: res.r.id };
            localStorage.setItem("Step1Data", JSON.stringify(updatedValues));
            let localdata = JSON.parse(localStorage.getItem("Fiesta")) || {};
            localdata.company_id = id;
            localStorage.setItem("Fiesta", JSON.stringify(localdata));
          }
        }
        if (res?.s) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  useEffect(() => {
    const fetchStep1Data = async () => {
      const savedData = JSON.parse(localStorage.getItem("Step1Data"));
      if (savedData) {
        formik.setValues(savedData);
      }
    };
    fetchStep1Data();
  }, []);
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  return (
    <Box component="form" id="step1-form" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          fontSize: { xs: "24px", fontWeight: "600", padding: "1rem" },
        }}
      >
        {t("becomePartner.step1.title")}
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box pb={0.5}>
              <StarSVG /> {t("becomePartner.step1.company_name")}
            </Box>

            <TextField
              type="text"
              id="company_name"
              name="company_name"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                if (
                  value.trimStart() !== value &&
                  value.trimStart() !== value
                ) {
                  e.target.value = value.trimStart();
                  e.target.value = value.trimEnd();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.company_name}
              error={
                formik.touched.company_name &&
                Boolean(formik.errors.company_name)
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
              }
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_company_name")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box pb={0.5}>
              <StarSVG /> {t("becomePartner.step1.city")}
            </Box>

            <TextField
              type="text"
              id="city"
              name="city"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                if (
                  value.trimStart() !== value &&
                  value.trimStart() !== value
                ) {
                  e.target.value = value.trimStart();
                  e.target.value = value.trimEnd();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_city")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box pb={0.5}>
              <StarSVG /> {t("becomePartner.step1.post_Code")}
            </Box>

            <TextField
              type="number"
              id="postal_code"
              name="postal_code"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;

                // Prevent negative numbers
                if (value && Number(value) < 0) {
                  e.target.value = ""; // Clear the input if the value is negative
                  return; // Prevent further processing
                }

                // Trim whitespace
                e.target.value = value.trim();

                // Update formik state
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.postal_code}
              error={
                formik.touched.postal_code && Boolean(formik.errors.postal_code)
              }
              helperText={
                formik.touched.postal_code && formik.errors.postal_code
              }
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_post_Code")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box pb={0.5}>
              <StarSVG /> {t("becomePartner.step1.weblink")}
            </Box>

            <TextField
              type="text"
              id="website_link"
              name="website_link"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                if (
                  value.trimStart() !== value &&
                  value.trimStart() !== value
                ) {
                  e.target.value = value.trimStart();
                  e.target.value = value.trimEnd();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.website_link}
              error={
                formik.touched.website_link &&
                Boolean(formik.errors.website_link)
              }
              helperText={
                formik.touched.website_link && formik.errors.website_link
              }
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_weblink")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Step1;
