import React from 'react'

const MessageHandler = () => {
  return (
    <div>
          MessageHandler
    </div>
  )
}

export default MessageHandler
