import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { Box, useTheme, FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import MultiImageUpload from "../../../Component/ImageUploader/MultiImageUploader";
import { API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import MultiCerti from "../../../Component/ImageUploader/MultiCertficate";

const Step4 = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [comp_id, setCompId] = useState(0);
  const [images, setImages] = useState([]);
  const [certificate, setcertificate] = useState([]);

  const formik = useFormik({
    initialValues: {
      images: [],
      certificates: [],
    },
    validationSchema: Yup.object({
      images: Yup.array().min(3, "At least three images are required."),
      certificates: Yup.array().min(1, "At least one certificate is required."),
    }),
    onSubmit: async (values) => {
      try
      {
        for (let i = 0; i < values.images.length; i++)
        {
          let f = new FormData();
          f.append("company_id", comp_id);
          f.append("type", 1);
          f.append("image", values.images[i]);
          await API_POST(API_endpoints.addIandC, f);
        }

        for (let i = 0; i < values.certificates.length; i++)
        {
          let q = new FormData();
          q.append("company_id", comp_id);
          q.append("type", 2);
          q.append("image", values.certificates[i].file);
          q.append("name", values.certificates[i].name);
          await API_POST(API_endpoints.addIandC, q);
        }
      } catch (error)
      {
        console.error(error);
      }
    },
  });
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  const handleImageChange = (newFiles) => {
    formik.setFieldValue("images", [...formik.values.images, ...newFiles]);
  };

  const handleCertificateChange = (newFiles) => {
    const updatedCertificates = newFiles.map((file, index) => ({
      file,
      title: formik.values.certificates[index]?.title || "",
    }));

    formik.setFieldValue("certificates", [
      ...formik.values.certificates,
      ...updatedCertificates,
    ]);
  };

  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  const handleUserData = async () => {
    let parseData = JSON.parse(localStorage.getItem("Fiesta"));
    let id = parseData?.id;

    if (!id) return; // Exit if there's no id

    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);

      if (
        res?.r?.company_details?.image &&
        Array.isArray(res.r.company_details.image)
      )
      {
        let imagesArray = [];
        let certificatesArray = [];

        res.r.company_details.image.forEach((e) => {
          if (e.type === 1)
          {
            imagesArray.push(e);
          } else if (e.type === 2)
          {
            certificatesArray.push(e);
          }
        });

        formik.setFieldValue("images", imagesArray);
        setImages(imagesArray);
        setcertificate(certificatesArray);
        formik.setFieldValue("images", imagesArray);
        formik.setFieldValue(
          "certificates",
          certificatesArray.map((cert) => ({
            file: cert.image,
            name: cert.name || "",
          }))
        );
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);

  useEffect(() => {
    if (!formik.values.company_id)
    {
      let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
      let companyId = parsedData?.company_id;

      if (companyId && comp_id !== companyId)
      {
        setCompId(companyId);
      }
    }
  }, [formik.values.company_id, comp_id]);


  return (
    <Box>
      <Box
        sx={{
          fontSize: { xs: "25px", fontWeight: "600", padding: "1rem" },
        }}
      >
        {t("becomePartner.step4.title")}
      </Box>

      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        {/* Image Upload Section */}
        <Box>
          <Box py={1}>
            <Box>
              <StarSVG /> {t("becomePartner.step4.image.title")}
            </Box>
            <Box
              sx={{
                pl: "1.35rem",
                color: theme.palette.text.subHead,
                my: 0.5,
                fontSize: "15px",
              }}
            >
              {t("becomePartner.step4.image.note")}
            </Box>
          </Box>
          <MultiImageUpload
            apiPreviews={images ?? []}
            formik={formik}
            setImages={handleImageChange}
            imgTitle={false}
            max={3}
            handleUserData={handleUserData}
          />
          {formik.touched.images && formik.errors.images && (
            <FormHelperText error>{formik.errors.images}</FormHelperText>
          )}
        </Box>

        {/* Certificate Upload Section */}
        <Box>
          <Box py={1}>
            <Box>
              <StarSVG /> {t("becomePartner.step4.certi.title")}
            </Box>
            <Box
              sx={{
                pl: "1.35rem",
                color: theme.palette.text.subHead,
                my: 0.5,
                fontSize: "15px",
              }}
            >
              {t("becomePartner.step4.certi.note")}
            </Box>
          </Box>
          <MultiCerti
            setImages={handleCertificateChange}
            imgTitle={true}
            max={5}
            certificates={certificate}
            formik={formik}
            handleUserData={handleUserData}
            comp_id={comp_id}
          />
          {formik.touched.certificates && formik.errors.certificates && (
            <FormHelperText error>{formik.errors.certificates}</FormHelperText>
          )}
        </Box>
      </Box>
    </Box>
  );
});

export default Step4;
