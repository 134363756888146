import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import catering from "../../../../Assets/images/catering.png";

const CateringCard = ({ data }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "9px",
        position: "relative",
        background: theme.palette.background.section,
        mt: "2rem !important",
      }}
    >
      <Box
        className="flexCenter"
        position={"absolute"}
        sx={{ width: "100%", height: "100px" }}
        top={"-40px"}
      >
        <img
          src={catering}
          alt="catering"
          style={{ height: "100px", width: "100px" }}
        />
      </Box>
      <Box sx={{ height: "70px" }}></Box>

      <Box sx={{ background: theme.palette.background.section, padding: 2 }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.background.dark,
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {data.title}
        </Typography>
        <Box
          sx={{
            height: "80px",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 500,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
            }}
          >
            {data?.desc}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "240px",
          width: "100%",
          padding: 2,
          paddingTop: 0,
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
          <img
            src={data?.image}
            className="scaleImg"
            alt=""
            style={{ height: "100%", width: "100%", borderRadius: "4px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CateringCard;
