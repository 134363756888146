import { Box, useTheme, Typography } from "@mui/material";
import BannerImage from "../../Component/BannerImage/BannerImage";
import DashboardTab from "./Dashboard/DashboardTab";
import { useTranslation } from "react-i18next";
import MyProfileTab from "./MyProfile/MyProfileTab";
import MyPaymentTab from "./MyPayment/MyPaymentTab";
import CustomerReviewsTab from "./CustomerReviews/CustomerReviewsTab";
import MyBookingTab from "./MyBookings/MyBookingTab";
import { useEffect, useState } from "react";
import img from "../../Assets/images/add_profile_pic.png";
import MyCompanyTab from "./MyCompany/MyCompanyTab";
import ButtonComponent from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SingleImage from "../../Component/ImageUploader/SingleImage";
import { API_GET_USERID, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { useFormik } from "formik";

const TruckOwnerProfileScreen = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const [Loading, setLoading] = useState(true);
  const nav = useNavigate();
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let UserID = parseData?.id;
  const tabList = [
    {
      id: 0,
      label: t("ownerProfile.menu.dash.title"),
      data: <DashboardTab />,
    },
    {
      id: 1,
      label: t("ownerProfile.menu.profile.title"),
      data: <MyProfileTab />,
    },
    {
      id: 2,
      label: t("ownerProfile.menu.company.title"),
      data: <MyCompanyTab />,
    },
    {
      id: 3,
      label: t("ownerProfile.menu.payment.title"),
      data: <MyPaymentTab />,
    },
    {
      id: 4,
      label: t("ownerProfile.menu.review.title"),
      data: <CustomerReviewsTab />,
    },
    {
      id: 5,
      label: t("ownerProfile.menu.booking.title"),
      data: <MyBookingTab />,
    },
  ];

  const handleLogout = () => {
    localStorage.clear();
    const event = new CustomEvent("authUpdate", {
      detail: { isLoggedIn: false },
    });
    window.dispatchEvent(event);
    toast.success("Logout Successfully");
    nav("/");
  };

  const formik = useFormik({
    initialValues: {
      profile: null,
      fullname: "",
    },

    onSubmit: async (values) => {
      const f = new FormData();
      f.append("user_id", UserID);
      f.append("profile_img", formik.values.profile);

      try
      {
        const res = await API_POST(API_endpoints.updateuser, f);
        if (res.s)
        {
          handleUserData();
        } else
        {
          toast.error(res.m);
        }
      } catch (error)
      {
        console.error("Error:", error);
      }
    },
  });

  const handleUserData = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, UserID);
      formik.setValues({
        profile: res.r?.profile_img || null,
        fullname: res?.r?.full_name
      });
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);

  return (
    <Box>
      <BannerImage path={t("ownerProfile.myProfile")} />

      <Box
        sx={{
          background: theme.palette.background.gd,
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "90%",
            m: "auto",
            maxWidth: "1400px",
          }}
        >
          {/* image - name  */}
          <Box
            sx={{
              background: theme.palette.background.main,
              width: "100%",
              m: "auto",
              height: { xs: "120px", md: "160px" },
              borderBottomLeftRadius: "35px",
              borderBottomRightRadius: "35px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ position: "absolute", top: { xs: "-50px", md: "-70px" } }}
              >
                {/* <Box sx={{ height: { xs: "100px", md: "140px" }, width: { xs: "100px", md: "140px" } }}>
                  <img
                    src={img}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      zIndex: 1,
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                </Box> */}
                <Box
                  sx={{
                    m: "auto",
                    width: "100px",
                    height: "140px",
                    cursor: "pointer",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SingleImage
                    apiPreview={
                      typeof formik.values.profile === "string"
                        ? API_endpoints?.baseURL + formik.values.profile
                        : null
                    }
                    setImage={formik.setFieldValue}
                    name="profile"
                    autoSubmit={formik.handleSubmit}

                  />
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-25px",
                      m: "auto",
                      background: theme.palette.background.orange,
                      p: 1,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        padding: "0px 18px",
                        color: theme.palette.text.defaultLight,
                        fontWeight: "600",
                      }}
                    >
                      {formik.values.fullname}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 20,
                  display: "flex",
                  width: "95%",
                  m: "auto",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", md: "22px" },
                    fontWeight: 700,
                  }}
                >
                  {t("ownerProfile.title")}
                </Typography>

                <ButtonComponent
                  label={`${t("Navbar.Logout")}`}
                  onClick={() => handleLogout()}
                  sx={{ border: `1px solid ${theme.palette.background.btn}` }}
                />
              </Box>
            </Box>
          </Box>

          {/* tab */}
          <Box>
            {/* tab head */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: { xs: 3, md: 5 },
                py: { xs: 2, md: 3 },
                px: 4,
                overflow: "auto",
              }}
            >
              {tabList.map((e, i) => (
                <Box
                  key={i}
                  className="link"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: e.id === currentTab && theme.palette.text.active,
                    fontWeight: e.id === currentTab && 600,
                    fontSize: { xs: "18px", md: "20px" },
                    cursor: "pointer",
                    textWrap: "nowrap",
                    "&:hover": {
                      color: theme.palette.text.active,
                    },
                  }}
                  onClick={() => setCurrentTab(e.id)}
                >
                  {e?.label}
                </Box>
              ))}
            </Box>
            {/* tab data */}
            <Box sx={{ pb: 5 }}>
              <Box
                sx={{
                  background: theme.palette.background.main,
                  width: "100%",
                  m: "auto",
                  borderRadius: "35px",
                  position: "relative",
                  p: 4,
                }}
              >
                {tabList[currentTab].data}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TruckOwnerProfileScreen;
