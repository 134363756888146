import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, TextField, useTheme, Radio, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import ButtonComponent from "../../../Component/Button/Button";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import { toast } from "react-toastify";
import { json, useNavigate } from "react-router-dom";
import { API_GET_USERID } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const Step5 = forwardRef((props, ref) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const { id } = JSON.parse(localStorage.getItem("Fiesta"));
  const theme = useTheme();
  const [comp_id, setCompId] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      member: "", // Radio selection
      discount: "", // Discount input field
    },
    validationSchema: Yup.object({
      member: Yup.string().required(t("becomePartner.step5.required")),
    }),
    onSubmit: async (values) => {
      toast.success(
        t("Congratulations! You've successfully become a partner.")
      );
      localStorage.setItem("isPartner", true);
      localStorage.removeItem("Step1Data");
      localStorage.removeItem("Step2Data");
      localStorage.removeItem("Step3Data");
      localStorage.removeItem("Step4Data");
      localStorage.removeItem("Step5Data");
      handleUserData(id);
      navigate("/");
    },
  });

  const handleUserData = async (id) => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      res?.s && localStorage.setItem("Fiesta", JSON.stringify(res?.r));
    } catch (error) {
      console.log(error);
    }
  };

  const membershipOptions = [
    { item: "6", price: 66 },
    { item: "12", price: 120 },
  ];

  const calculateTotalPrice = () => {
    const selectedOption = membershipOptions.find(
      (option) => option.item === selectedValue
    );
    const discountValue = formik.values.discount || 0;

    if (selectedOption) {
      const basePrice = selectedOption.price;

      const finalPrice = basePrice;

      setTotalPrice(finalPrice > 0 ? finalPrice : 0);
    }
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedValue, formik.values.discount]);
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    formik.setFieldValue("member", event.target.value);
  };

  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  useEffect(() => {
    if (!formik.values.company_id) {
      let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
      let companyId = parsedData?.company_id;
      setCompId(companyId);
    }
  }, []);
  return (
    <Box>
      <Typography variant="h4" fontWeight={600} padding="1rem">
        {t("becomePartner.step5.title")}
      </Typography>

      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {/* Member Selection */}
        <Box>
          <StarSVG /> {t("becomePartner.step5.member.title")}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          {membershipOptions.map((value) => (
            <Box
              key={value.item}
              sx={{
                width: "100%",
                borderRadius: "9px",
                background: value.item === "6" ? "#FFF6ED" : "#F5F5F5",
                padding: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
              }}
            >
              <Box>
                <Radio
                  onChange={handleRadioChange}
                  checked={selectedValue === value.item}
                  value={value.item}
                  name="member"
                  id={value.item}
                />
                <label htmlFor={value.item}>
                  {t(`becomePartner.step5.member.${value.item}`)}
                </label>
              </Box>
              <Box
                sx={{
                  fontSize: "24px",
                  color: theme.palette.text.darkOrange,
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {value.price}
                <EuroRoundedIcon />
              </Box>
            </Box>
          ))}
        </Box>
        {formik.touched.member && formik.errors.member && (
          <Typography color="error">{formik.errors.member}</Typography>
        )}

        {/* Discount Input */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                // marginBottom: "8px",
              }}
            >
              <StarSVG />
              <Typography>{t("becomePartner.step5.discount.title")}</Typography>
            </Box>

            <TextField
              id="discount"
              name="discount"
              type="number"
              {...formik.getFieldProps("discount")}
              fullWidth
              error={formik.touched.discount && Boolean(formik.errors.discount)}
              helperText={formik.touched.discount && formik.errors.discount}
              variant="outlined"
              placeholder={t("becomePartner.step5.discount.pl")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    bgcolor: theme.palette.background.inputBg,
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* Total Price Display */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            {t("becomePartner.step5.total")} : {totalPrice}€
          </Typography>
        </Box>

        {/* Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          <Box>
            <ButtonComponent
              onClick={formik.handleSubmit}
              label={`${t("becomePartner.step5.button")}`}
              sx={{
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Step5;
