import { Box, Typography, useTheme,  Grid } from "@mui/material";
import ButtonComponent from "../../../../Component/Button/Button";

const CatServiceCard = ({ data }) => {
  const theme = useTheme();
  return (

    <Grid
      container
      sx={{
        boxShadow: 3,
        borderRadius: "8px",
        mt: 2,
        flexDirection: { xs: "column", sm: "row" },
        height: { xs: "auto", sm: "auto", md: '225px' },
        overflow: "hidden",
        backgroundColor: theme.palette.background.main,
        width: { xs: "100%", sm: "100%", md: "100%" },
        maxWidth: { sm: "600px", md: "100%" },
        m: "auto",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        sx={{
          width: { xs: "100%", sm: "150px", md: '130px', xl: "200px" },
          height: { xs: "200px", sm: "150px", md: '100%' },
          overflow: "hidden",
        }}
      >
        <img
          src={data?.image}
          className="scaleImg"
          alt={data?.title || "Catering Service Image"}
          style={{
            height: "100%",
            width: "100%",

            objectFit: "cover",
            borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: { xs: 2, sm: 2, xl: 4 },
          backgroundColor: theme.palette.background.main,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: { xs: 'center', md: "space-between" }, alignItems: { xs: 'center', md: "start" } }}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 700,
              mb: 1,
            }}
          >
            {data?.title}
          </Typography>

          <Typography
            sx={{
              color: theme.palette.text.darkOrange,
              fontWeight: 500,
              mb: 1,
            }}
          >
            Business caterer / Barista
          </Typography>

          {/* Categories */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {["Pizza", "Burger", "Frenkie"].map((category, i) => (
              <Typography
                key={i}
                sx={{
                  backgroundColor: theme.palette.background.label,
                  padding: "4px 8px",
                  borderRadius: "8px",
                  color: theme.palette.text.label,
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {category}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box sx={{ mt: 2, pb: 2, display: "flex", justifyContent: { xs: 'center', md: "start" }, }}>
          <ButtonComponent
            label="Book Now"
            sx={{
              background: theme.palette.background.btn,
              color: theme.palette.text.primary,
              padding: "4px !important",
              fontSize: "12px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: theme.palette.background.btn,
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>

  );
};

export default CatServiceCard;
