import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { API_GET } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";

const TermsAndConditionsScreen = () => {
    const theme = useTheme();
    const { t } = useTranslation()
    const [data, setData] = useState("");
    const fetchData = async () => {
        try
        {
            const res = await API_GET(API_endpoints.GetTerms);
            setData(res?.r?.content);
        } catch (error)
        {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        window.scrollTo({ behavior: "instant", top: 0 });
    }, []);
    return (
        <Box sx={{ background: theme.palette.background.gd, }}>
            <Box
                sx={{
                    width: "90%",
                    maxWidth: "1440px",
                    m: "auto", pb: 5

                }}
            >
                <Box className="flexCenter" sx={{ height: "150px" }}>
                    <Typography
                        sx={{ fontSize: "32px", textAlign: "center" }}
                        className="fontSnugle"
                    >
                        {t('TC.title')}
                    </Typography>
                </Box>
                <Box sx={{ p: { xs: 2, md: 5 }, borderRadius: '25px', background: "#fff", minHeight: "50vh", width: "90%", m: 'auto' }}>
                    <span dangerouslySetInnerHTML={{ __html: data }} />
                </Box>
            </Box>
        </Box>
    );
};

export default TermsAndConditionsScreen;
