// import { Box, Typography, useTheme } from "@mui/material";
// import { useEffect, useRef, useState } from "react";
// import demo from "../../../../Assets/images/catering.png";
// import { useTranslation } from "react-i18next";
// import del from "../../../../Assets/images/delete.svg";
// import add from "../../../../Assets/images/addImage.svg";
// import ButtonComponent from "../../../../Component/Button/Button";
// import Loader from "../../../../Component/Loader/Loader";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import { API_GET_OPEN, API_GET_USERID, API_POST } from "../../../../API/config";
// import { API_endpoints } from "../../../../API/API_Endpoints";
// import { toast } from "react-toastify";

// const OwnerImageTab = () => {
//     const { t } = useTranslation();
//     const theme = useTheme();
//     const [loading, setLoading] = useState(false);
//     const [isHovered, setisHovered] = useState(null);
//     const [compID, setcompID] = useState(0);
//     const [imageType, setImageType] = useState(0);
//     let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
//     let id = parsedData?.id;

//     const fileInputRef = useRef(null);
//     const handleBoxClick = () => {
//         fileInputRef.current.click();
//     };

//     const handleCertificateChange = (newFiles) => {
//         let files = newFiles.target.files
//         if (imageType === 1)
//         {
//             formik.setFieldValue("images", [...formik.values.images, ...files]);
//             console.log([...formik.values.images, ...files])
//         } else
//         {
//             const updatedCertificates = files.map((file, index) => ({
//                 file,
//                 title: formik.values.certificates[index]?.title || "",
//             }));

//             formik.setFieldValue("certificates", [
//                 ...formik.values.certificates,
//                 ...updatedCertificates,
//             ]);
//         }
//     };
//     const formik = useFormik({
//         initialValues: {
//             id: "",
//             user_id: "",
//             images: [],
//             certificates: [],
//             image: [],
//         },

//         // validationSchema: Yup.object({}),

//         onSubmit: async (values) => {
//             let isSubmitted = false;
//             try
//             {
//                 setLoading(true);
//                 for (let i = 0; i < values.images.length; i++)
//                 {
//                     if (values.images[i].name)
//                     {
//                         let f = new FormData();
//                         f.append("company_id", compID);
//                         f.append("type", 1);
//                         f.append("image", values.images[i]);
//                         await API_POST(API_endpoints.addIandC, f);
//                     }
//                 }

//                 for (let i = 0; i < values.certificates.length; i++)
//                 {
//                     if (values.certificates[i].name)
//                     {
//                         let q = new FormData();
//                         q.append("company_id", compID);
//                         q.append("type", 2);
//                         q.append("image", values.certificates[i].file);
//                         q.append("name", values.certificates[i].name);
//                         await API_POST(API_endpoints.addIandC, q);
//                     }
//                     isSubmitted = true;
//                 }
//                 if (isSubmitted)
//                 {
//                     toast.success("Submitted successfully");
//                 }
//             } catch (error)
//             {
//                 console.error("Error:", error);
//             } finally
//             {
//                 setLoading(false)
//             }
//         },
//     });

//     const getData = async (id) => {
//         try
//         {
//             let res = await API_GET_OPEN(
//                 `${API_endpoints.getcompanybyid}?company_id=${id}`
//             );
//             formik.setValues({
//                 images: res?.r?.image.map((item) => item.type === 1 && item),
//                 certificates: res?.r?.image.map((item) => item.type === 2 && item),
//                 user_id: res?.r?.user_id,
//                 id: res?.r?.id,
//             });
//         } catch (error)
//         {
//             console.log(error);
//         }
//     };

//     const getCompanyId = async () => {
//         try
//         {
//             let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
//             if (res.s)
//             {
//                 getData(res?.r?.company_details?.id);
//                 setcompID(res?.r?.company_details?.id);
//             }
//         } catch (error)
//         {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         getCompanyId();
//     }, []);

//     return (
//         <>
//             <Box sx={{ my: 2 }}>
//                 <Typography sx={{ fontSize: { xs: "18px" }, fontWeight: "500" }}>
//                     {t("ownerProfile.menu.profile.company")}
//                 </Typography>
//                 {/* <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} /> */}
//                 <Box
//                     sx={{
//                         display: "flex",
//                         gap: 2,
//                         p: 2,
//                         my: 1,
//                         borderRadius: "12px",
//                         border: "1px solid #1A130C28",
//                     }}
//                 >
//                     {formik?.values?.images?.map((x, i) => {
//                         return (
//                             x?.type === 1 && (
//                                 <Box
//                                     key={i}
//                                     onMouseEnter={() => setisHovered("i-" + i)}
//                                     onMouseLeave={() => setisHovered(null)}
//                                     sx={{
//                                         height: "100px",
//                                         width: "175px",
//                                         borderRadius: "12px",
//                                         position: "relative",
//                                     }}
//                                 >
//                                     <img
//                                         src={API_endpoints.baseURL + x?.image}
//                                         alt=""
//                                         style={{
//                                             height: "100%",
//                                             width: "100%",
//                                             borderRadius: "14px",
//                                             objectFit: "cover",
//                                         }}
//                                     />
//                                     <img
//                                         src={del}
//                                         alt=""
//                                         style={{
//                                             display: isHovered === "i-" + i ? "block" : "none",
//                                             height: "35px",
//                                             width: "35px",
//                                             position: "absolute",
//                                             right: "-12px",
//                                             top: "-12px",
//                                             borderRadius: "14px",
//                                         }}
//                                     />
//                                 </Box>
//                             )
//                         );
//                     })}
//                     <Box
//                         sx={{
//                             height: "100px",
//                             width: "175px",
//                             borderRadius: "12px",
//                             position: "relative",
//                         }}
//                         onClick={() => {
//                             handleBoxClick();
//                             setImageType(1);
//                         }}
//                     >
//                         <img
//                             src={add}
//                             alt=""
//                             style={{
//                                 height: "100%",
//                                 width: "100%",
//                                 borderRadius: "14px",
//                             }}
//                         />
//                     </Box>
//                 </Box>
//             </Box>
//             <Box sx={{ my: 2 }}>
//                 <Typography sx={{ fontSize: { xs: "18px" }, fontWeight: "500" }}>
//                     {t("ownerProfile.menu.profile.certi")}
//                 </Typography>
//                 {/* <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} /> */}
//                 <Box
//                     sx={{
//                         display: "flex",
//                         gap: 2,
//                         p: 2,
//                         my: 1,
//                         borderRadius: "12px",
//                         border: "1px solid #1A130C28",
//                     }}
//                 >
//                     {formik?.values?.certificates?.map((x, i) => {
//                         return (
//                             x?.type === 2 && (
//                                 <Box
//                                     key={i}
//                                     onMouseEnter={() => setisHovered("c-" + i)}
//                                     onMouseLeave={() => setisHovered(null)}
//                                     sx={{
//                                         height: "100px",
//                                         width: "175px",
//                                         borderRadius: "12px",
//                                         position: "relative",
//                                     }}
//                                 >
//                                     <img
//                                         src={API_endpoints.baseURL + x?.image}
//                                         alt=""
//                                         style={{
//                                             height: "100%",
//                                             width: "100%",
//                                             borderRadius: "14px",
//                                             objectFit: "cover",
//                                         }}
//                                     />
//                                     <img
//                                         src={del}
//                                         alt=""
//                                         style={{
//                                             display: isHovered === "c-" + i ? "block" : "none",
//                                             height: "35px",
//                                             width: "35px",
//                                             position: "absolute",
//                                             right: "-12px",
//                                             top: "-12px",
//                                             borderRadius: "14px",
//                                         }}
//                                     />
//                                 </Box>
//                             )
//                         );
//                     })}
//                     <Box
//                         sx={{
//                             height: "100px",
//                             width: "175px",
//                             borderRadius: "12px",
//                             position: "relative",
//                             cursor: "pointer",
//                             overflow: "hidden",
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                         }}
//                         onClick={() => {
//                             handleBoxClick();
//                             setImageType(2);
//                         }}
//                     >
//                         {/* Hidden File Input */}
//                         <input
//                             type="file"
//                             name="image"
//                             id="image"
//                             style={{ display: "none" }}
//                             ref={fileInputRef}
//                             multiple
//                             onChange={handleCertificateChange}
//                             accept="image/*"
//                         />

//                         <img
//                             src={add}
//                             alt="Upload"
//                             style={{
//                                 height: "100%",
//                                 width: "100%",
//                                 borderRadius: "14px",
//                             }}
//                         />
//                     </Box>
//                 </Box>
//             </Box>
//             <Box className="flexCenter" sx={{ my: 5 }}>
//                 <ButtonComponent
//                     disabled={loading}
//                     type="button"
//                     label={
//                         loading ? <Loader /> : `${t("ownerProfile.menu.profile.update")}`
//                     }
//                     onClick={formik.handleSubmit}
//                     sx={{
//                         width: { xs: "80%", sm: "130px" },
//                         background: theme.palette.background.btn,
//                         color: theme.palette.text.primary,
//                     }}
//                 />
//             </Box>
//         </>
//     );
// };

// export default OwnerImageTab;

import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import del from "../../../../Assets/images/delete.svg";
import add from "../../../../Assets/images/addImage.svg";
import ButtonComponent from "../../../../Component/Button/Button";
import Loader from "../../../../Component/Loader/Loader";
import { useFormik } from "formik";
import { API_GET_OPEN, API_GET_USERID, API_POST } from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { toast } from "react-toastify";

const OwnerImageTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isHovered, setisHovered] = useState(null);
  const [compID, setcompID] = useState(0);
  const [imageType, setImageType] = useState(0);
  let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parsedData?.id;

  const fileInputRef = useRef(null);
  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (imageType === 1) {
      const updatedImages = files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        isLocal: true,
      }));
      formik.setFieldValue("images", [
        ...formik.values.images,
        ...updatedImages,
      ]);
    } else {
      const updatedCertificates = files.map((file) => ({
        file,
        title: "",
        preview: URL.createObjectURL(file),
        isLocal: true,
      }));
      formik.setFieldValue("certificates", [
        ...formik.values.certificates,
        ...updatedCertificates,
      ]);
    }
  };

  const handleDeleteImage = async (index, type) => {
    let valuesKey = type === 1 ? "images" : "certificates";
    let items = formik.values[valuesKey];

    if (items[index]?.id) {
      try {
        setLoading(true);
        const f = new FormData();
        f.append("id", items[index]?.id);
        f.append("type", type);
        f.append("status", 0);
        let res = await API_POST(API_endpoints.deleteImage, f);
        res?.s && toast.success(res.m);
      } catch (error) {
        console.error("Failed to delete image:", error);
      } finally {
        setLoading(false);
      }
    }

    // Remove the image from the local state
    const updatedItems = items.filter((_, i) => i !== index);
    formik.setFieldValue(valuesKey, updatedItems);
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      user_id: "",
      images: [],
      certificates: [],
      //   image: [],
    },

    onSubmit: async (values) => {
      let isSubmitted = false;
      try {
        setLoading(true);
        // Submit images to API
        for (let i = 0; i < values.images.length; i++) {
          const image = values.images[i];
          if (image.isLocal) {
            let formData = new FormData();
            formData.append("company_id", compID);
            formData.append("type", 1);
            formData.append("image", image.file);
            await API_POST(API_endpoints.addIandC, formData);
          }
        }

        // Submit certificates to API
        for (let i = 0; i < values.certificates.length; i++) {
          const certificate = values.certificates[i];
          if (certificate.isLocal) {
            let formData = new FormData();
            formData.append("company_id", compID);
            formData.append("type", 2);
            formData.append("image", certificate.file);
            formData.append("name", certificate.title);
            await API_POST(API_endpoints.addIandC, formData);
          }
        }
        isSubmitted = true;
        if (isSubmitted) {
          toast.success("Submitted successfully");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const getData = async (id) => {
    try {
      let res = await API_GET_OPEN(
        `${API_endpoints.getcompanybyid}?company_id=${id}`
      );
      formik.setValues({
        images: res?.r?.image
          .filter((item) => item.type === 1)
          .map((item) => ({ ...item, isLocal: false })),
        certificates: res?.r?.image
          .filter((item) => item.type === 2)
          .map((item) => ({ ...item, isLocal: false })),
        user_id: res?.r?.user_id,
        id: res?.r?.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyId = async () => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s) {
        getData(res?.r?.company_details?.id);
        setcompID(res?.r?.company_details?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Typography sx={{ fontSize: { xs: "18px" }, fontWeight: "500" }}>
          {t("ownerProfile.menu.profile.company")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            p: 2,
            my: 1,
            borderRadius: "12px",
            border: "1px solid #1A130C28",
          }}
        >
          {formik?.values?.images?.map((x, i) => (
            <Box
              key={i}
              onMouseEnter={() => setisHovered("i-" + i)}
              onMouseLeave={() => setisHovered(null)}
              sx={{
                height: "100px",
                width: "175px",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <img
                src={x.isLocal ? x.preview : API_endpoints.baseURL + x.image}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "14px",
                  objectFit: "cover",
                }}
              />
              <img
                src={del}
                alt=""
                onClick={() => handleDeleteImage(i, 1)}
                style={{
                  display: isHovered === "i-" + i ? "block" : "none",
                  height: "35px",
                  width: "35px",
                  position: "absolute",
                  right: "-12px",
                  top: "-12px",
                  borderRadius: "14px",
                  cursor: "pointer",
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              height: "100px",
              width: "175px",
              borderRadius: "12px",
              position: "relative",
            }}
            onClick={() => {
              handleBoxClick();
              setImageType(1);
            }}
          >
            <img
              src={add}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "14px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography sx={{ fontSize: { xs: "18px" }, fontWeight: "500" }}>
          {t("ownerProfile.menu.profile.certi")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            p: 2,
            my: 1,
            borderRadius: "12px",
            border: "1px solid #1A130C28",
          }}
        >
          {formik?.values?.certificates?.map((x, i) => (
            <Box
              key={i}
              onMouseEnter={() => setisHovered("c-" + i)}
              onMouseLeave={() => setisHovered(null)}
              sx={{
                height: "100px",
                width: "175px",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <img
                src={x.isLocal ? x.preview : API_endpoints.baseURL + x.image}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "14px",
                  objectFit: "cover",
                }}
              />
              <img
                src={del}
                alt=""
                onClick={() => handleDeleteImage(i, 2)}
                style={{
                  display: isHovered === "c-" + i ? "block" : "none",
                  height: "35px",
                  width: "35px",
                  position: "absolute",
                  right: "-12px",
                  top: "-12px",
                  borderRadius: "14px",
                  cursor: "pointer",
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              height: "100px",
              width: "175px",
              borderRadius: "12px",
              position: "relative",
              cursor: "pointer",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              handleBoxClick();
              setImageType(2);
            }}
          >
            {/* Hidden File Input */}
            <input
              type="file"
              name="image"
              id="image"
              style={{ display: "none" }}
              ref={fileInputRef}
              multiple
              onChange={handleFileChange}
              accept="image/*"
            />
            <img
              src={add}
              alt="Upload"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "14px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className="flexCenter" sx={{ my: 5 }}>
        <ButtonComponent
          disabled={loading}
          type="button"
          label={
            loading ? <Loader /> : `${t("ownerProfile.menu.profile.update")}`
          }
          onClick={formik.handleSubmit}
          sx={{
            width: { xs: "80%", sm: "130px" },
            background: theme.palette.background.btn,
            color: theme.palette.text.primary,
          }}
        />
      </Box>
    </>
  );
};

export default OwnerImageTab;
