import {
  Autocomplete,
  Box,
  FormControl,
  Paper,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { API_GET, API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import CustomPhoneNumber from "../../../Component/Input/PhoneInput";
import ForgotPasswordDialogue from "../../Auth/Login/ForgotPassword";
import demo from "../../../Assets/images/catering.png";
import del from "../../../Assets/images/delete.svg";
import add from "../../../Assets/images/addImage.svg";
import ButtonComponent from "../../../Component/Button/Button";
import Loader from "../../../Component/Loader/Loader";
import SingleImage from "../../../Component/ImageUploader/SingleImage";
import { toast } from "react-toastify";

const MyProfileTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let UserID = parseData?.id;
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isHovered, setisHovered] = useState(null);

  const InputWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    padding: "18px",
    borderRadius: "12px",
    border:
      formik.touched.dob && formik.errors.dob
        ? "1px solid red"
        : "1px solid #1A130C3D",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      border:
        formik.touched.dob && formik.errors.dob
          ? "1px solid red"
          : `1px solid ${theme.palette.background.lightorange}`,
      backgroundColor: theme.palette.background.inputBg,
    },
    '& input[type="date"]': {
      width: "100%",
      border: "none",
      backgroundColor: "transparent",
      color: theme.palette.text.defaultDark,
      padding: "0",
      margin: "0",
      "&:focus": {
        outline: "none",
      },
    },
  }));



  const formik = useFormik({
    initialValues: {
      fullname: "",
      country: 0,
      dob: "",
      phno: "",
      email: "",
      webLink: ""
    },

    validationSchema: Yup.object({
      fullname: Yup.string().required(t("Register.fullNameRequired")),
      webLink: Yup.string().required(t("")),
      country: Yup.string()
        .required(t("Register.countryRequired"))
        .notOneOf(["0"], t("Register.validCountry")),
      dob: Yup.date().required(t("Register.dobRequired")).nullable(),
      phno: Yup.string()
        .required(t("Register.numberRequired"))
        .matches(/^[0-9]+$/, t("Register.onlyNumbers")),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      const f = new FormData();
      f.append("full_name", formik.values.fullname);
      f.append("country_id", formik.values.country);
      f.append("dob", formik.values.dob);
      f.append("phno", formik.values.phno);
      f.append("link", formik.values.webLink);
      f.append("user_id", UserID);
      try
      {
        const res = await API_POST(API_endpoints.updateuser, f);
        if (res.s)
        {
          setLoading(false);
          toast.success(res.m);
        } else
        {
          setLoading(false);
          toast.error(res.m);
        }
      } catch (error)
      {
        console.error("Error:", error);
      }
    },
  });

  const handleCountry = async () => {
    try
    {
      let res = await API_GET(API_endpoints.countries);
      setCountries(res.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleUserData = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, UserID);
      formik.setValues({
        fullname: res.r?.full_name || "",
        dob: res.r?.dob || "",
        cc: res.r?.phno_cc || "",
        email: res?.r?.email || '',
        phno: res.r?.phno || "",
        webLink: res.r?.company_details.website_link || '',
        country: res.r?.country_id || 0,
      });
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };




  useEffect(() => {
    handleCountry();
    handleUserData()
  }, []);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.menu.profile.note")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #1A130C28", my: 2 }} />
      <Box>
        <Box>


          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              alignItems: "center",
              pb: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type="text"
                id="fullname"
                name="fullname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullname}
                error={
                  formik.touched.fullname && Boolean(formik.errors.fullname)
                }
                helperText={formik.touched.fullname && formik.errors.fullname}
                variant="outlined"
                placeholder={t("Register.fullname")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl
                fullWidth
                error={formik.touched.country && Boolean(formik.errors.country)}
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Autocomplete
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                  id="country"
                  name="country"
                  options={countries || []}
                  getOptionLabel={(option) => option.country_name || ""}
                  value={
                    countries.find((c) => c.id === formik.values.country) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("country", newValue?.id || "");
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Register.selectcountry")}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                      sx={{
                        "& .MuiInputBase-input": {
                          color: theme.palette.text.defaultDark,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.strip,
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.background.strip, // Focused label color
                        },
                        "& .MuiFormHelperText-root": {
                          color: theme.palette.text.error, // Error text color
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "12px",
                            borderColor: `1px solid ${theme.palette.background.strip}`,
                          },
                        },
                      }}
                    />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.strip,
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  ListboxProps={{
                    sx: {
                      bgcolor: theme.palette.background.main, // Background of options list
                      color: theme.palette.text.defaultDark, // Text color of options
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          bgcolor: theme.palette.background.inputBgHover, // Option hover background
                          color: theme.palette.text.active, // Option hover text color
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              alignItems: "center",
              pb: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <InputWrapper>
                <input
                  type="date"
                  value={formik.values.dob}
                  onChange={(e) => formik.setFieldValue("dob", e.target.value)}
                  onBlur={formik.handleBlur} // Handle blur to trigger validation
                // style={{
                //   border:
                //     formik.touched.dob && formik.errors.dob
                //       ? "1px solid red"
                //       : "",
                // }}
                />
              </InputWrapper>{" "}
              {formik.touched.dob && formik.errors.dob && (
                <div
                  style={{
                    color: theme.palette.text.error,
                    fontSize: "12px",
                    textAlign: "start",
                  }}
                >
                  {formik.errors.dob}
                </div>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <CustomPhoneNumber
                name="phno"
                theme={theme}
                country={"us"}
                placeholder="Enter your Number"
                value={formik.values.cc + formik.values.phno}
                onChange={(value, country) => {
                  const countryCodeLength = country.dialCode.length;
                  const phon_cc = value.slice(0, countryCodeLength);
                  const phno = value.slice(countryCodeLength);
                  formik.setFieldValue("phno", phno);
                  formik.setFieldValue("cc", phon_cc);
                }}
                inputClass="inputClassCountry"
              />

              {formik.touched.phno && formik.errors.phno && (
                <div
                  style={{
                    color: "red",
                    marginTop: "2px",
                    textAlign: "left",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.phno}
                </div>
              )}
            </Box>
          </Box>



          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              alignItems: "center",
              pb: 1,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type="email"
                id="email"
                name="email"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.email}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                placeholder={t("Login.email")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type="text"
                id="webLink"
                name="webLink"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.webLink}
                // error={
                //   formik.touched.webLink && Boolean(formik.errors.webLink)
                // }
                // helperText={formik.touched.webLink && formik.errors.webLink}
                variant="outlined"
                // placeholder={t("Register.fullname")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                      cursor: 'default'
                    },
                  },
                }}
              />
            </Box>


          </Box>



          <Box sx={{ my: 1, mb: 2 }}>
            <ForgotPasswordDialogue title={t("myProfile.reset")} />
          </Box>


          <Box className="flexCenter" sx={{ my: 5 }}>
            <ButtonComponent
              disabled={loading}
              type="button"
              label={
                loading ? (
                  <Loader />
                ) : (
                  `${t("ownerProfile.menu.profile.update")}`
                )
              }
              onClick={formik.handleSubmit}
              sx={{
                width: { xs: "80%", sm: "130px" },
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProfileTab;
