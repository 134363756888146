export const BecomeAPartner_EN_Trans = {
  title1: "Become a Partner",
  title2: "Register your company",
  prev: "Previous",
  next: "Next",
  step1: {
    title: "1. Company Details",
    company_name: "Enter catering company name",
    city: "Enter city name",
    post_Code: "Enter postal code",
    weblink: "Enter your website link",
    pl_company_name: "Enter company name",
    pl_city: "Enter city name",
    pl_post_Code: "Enter postal code",
    pl_weblink: "Enter weblink",
    vali: {
      Required: "Required",
      company_name: "Invalid company name",
      city: "Invalid city name",
      post_Code: "Invalid postal code",
      weblink: "Invalid weblink",
      InvalidURL: "Invalid URL ",
    },
  },
  step2: {
    title: "2. What types of caterers?",
    types: {
      title: "What types of caterers?",
      type1: "Bussiness Caterer",
      type2: "Food Trucks",
      type3: "Barista/Bartender",
      type4: "Other",
    },
    yourSelf: {
      title: "More about yourself",
      pl: "About yourself",
      required: "Required",
    },
    foodTruckDetails: {
      title: "Food Truck Details",
      image: {
        title: "Upload Food Truck Featured Image",
        drag: "Click here to upload image or Drag & Drop",
      },
      category: {
        title: "Enter food truck category",
        pl: "Enter category",
      },
      zipCode: {
        title: "Zip Code",
        pl: "Enter zip code",
      },
      area: {
        title: "Radius work area",
        pl: "Enter radius area",
      },
      desc: {
        title: "Enter Description",
        pl: "Enter description",
      },
      truckName: {
        title: "Food Truck Name",
        pl: "Enter food truck name",
      },
      address: {
        title: "Food Truck Address",
        pl: "Enter food truck address",
      },
      specification: {
        title: "Food Truck Specification",
        pl: "Enter food truck specification",
      },
      state: {
        title: "State",
        pl: "Enter state name",
      },
      time: {
        title: "Select Timing",
        pl1: "Opening hour",
        pl2: "Closing hour",
      },
    },
    questions: {
      title: "Additional Questions",
      truckSize: {
        title: " Size of the food truck",
        pl: "Enter size of the food truck(meter)",
      },
      electricity: {
        title: "Electricity requirement",
        other: "Other",
      },
      operatingMode: {
        title: "The operating mode of the truck",
        op1: "Kwh",
        op2: "GAS",
      },
      water: {
        title: "Is a water connection required?",
        op1: "Yes",
        op2: "No",
      },
    },
  },
  step3: {
    title: "3. Meals & Diet",
    meals: {
      title: "Categorical classification of meals",
      1: "Pizza C Pasta",
      2: "Burger C BBQ",
      3: "Seafood",
      4: "African",
      5: "Mexican",
      6: "Coffee",
      7: "Asian C BBQ",
      8: "Asian C Sushi",
      9: "Arabic",
      10: "Indian",
      11: "Cocktails C Drinks",
      12: "Other",
    },
    spDiet: {
      title: "Do you offer a special diet?",
      1: "Vegetarian",
      2: "Vegan",
      3: "Gluten-free",
    },
    Atonemeal: "At least one meal must be selected.",
    Atonediet: "At least one diet must be selected.",
  },
  step4: {
    title: "4. Image upload",
    image: {
      title: "Upload Company Image",
      note: "3 images required",
    },
    certi: {
      title: "Upload Essential Certificates",
      note: "You can upload essential certificate like Food Service Establishment Permit, Seller’s Permit, Fire Certificate etc...",
      pl: "Enter certificate name",
      Required: "Required",
    },
  },
  step5: {
    title: "5. Membership choice",
    member: {
      title: "Please select the membership which is given below.",
      6: "6 months",
      12: "12 months",
    },
    discount: {
      title: "Discount Code",
      pl: "Enter Discount Code",
    },
    total: "Total",
    button: "Submit",
    required: "Required",
    congrats: "Congratulations! You've successfully become a partner.",
  },
};

export const BecomeAPartner_NL_Trans = {
  title1: "Word een partner",
  title2: "Registreer uw bedrijf",
  prev: "Vorige",
  next: "Volgende",

  step1: {
    title: "1. Bedrijfsgegevens",
    company_name: "Voer de naam van het cateringbedrijf in",
    city: "Voer de naam van de stad in",
    post_Code: "Voer de postcode in",
    weblink: "Voer uw website link in",
    pl_company_name: "Voer bedrijfsnaam in",
    pl_city: "Voer stadsnaam in",
    pl_post_Code: "Voer postcode in",
    pl_weblink: "Voer website link in",
    vali: {
      Required: "Verplicht",
      company_name: "Ongeldige bedrijfsnaam",
      city: "Ongeldige stadsnaam",
      post_Code: "Ongeldige postcode",
      weblink: "Ongeldige website link",
    },
  },
  step2: {
    title: "2. Welke soorten cateraars?",
    types: {
      title: "Welke soorten cateraars?",
      type1: "Zakelijke Cateraar",
      type2: "Foodtrucks",
      type3: "Barista/Barman",
      type4: "Anders",
    },
    yourSelf: {
      title: "Meer over uzelf",
      pl: "over uzelf",
      required: "Verplicht",
    },
    foodTruckDetails: {
      title: "Foodtruck Details",
      image: {
        title: "Upload een afbeelding van de foodtruck",
        drag: "Klik hier om een afbeelding te uploaden of sleep deze hierheen",
      },
      category: {
        title: "Voer de categorie van de foodtruck in",
        pl: "Voer categorie in",
      },
      zipCode: {
        title: "Postcode",
        pl: "Voer postcode in",
      },
      area: {
        title: "Werkgebied straal",
        pl: "Voer straal in",
      },
      desc: {
        title: "Voer een beschrijving in",
        pl: "Voer beschrijving in",
      },
      truckName: {
        title: "Naam van de foodtruck",
        pl: "Voer foodtruck naam in",
      },
      address: {
        title: "Adres van de foodtruck",
        pl: "Voer foodtruck adres in",
      },
      specification: {
        title: "Specificatie van de foodtruck",
        pl: "Voer specificatie in",
      },
      state: {
        title: "Provincie",
        pl: "Voer provincienaam in",
      },
      time: {
        title: "Selecteer openingstijden",
        pl1: "Openingstijd",
        pl2: "Sluitingstijd",
      },
    },
    questions: {
      title: "Aanvullende vragen",
      truckSize: {
        title: "Grootte van de foodtruck",
        pl: "Voer de grootte van de foodtruck in (meter)",
      },
      electricity: {
        title: "Stroomvereiste",
        other: "Anders",
      },
      operatingMode: {
        title: "De bedrijfsmodus van de truck",
        op1: "Kwh",
        op2: "GAS",
      },
      water: {
        title: "Is een wateraansluiting vereist?",
        op1: "Ja",
        op2: "Nee",
      },
    },
  },
  step3: {
    title: "3. Maaltijden & Dieet",
    meals: {
      title: "Categorische indeling van maaltijden",
      1: "Pizza & Pasta",
      2: "Burgers & BBQ",
      3: "Zeevruchten",
      4: "Afrikaans",
      5: "Mexicaans",
      6: "Koffie",
      7: "Aziatisch & BBQ",
      8: "Aziatisch & Sushi",
      9: "Arabisch",
      10: "Indiaas",
      11: "Cocktails & Dranken",
      12: "Anders",
    },
    spDiet: {
      title: "Biedt u een speciaal dieet aan?",
      1: "Vegetarisch",
      2: "Veganistisch",
      3: "Glutenvrij",
    },
    Atonemeal: "Mindestens eine Mahlzeit muss ausgewählt werden.",
    Atonediet: "Mindestens eine Diät muss ausgewählt werden.",
  },
  step4: {
    title: "4. Afbeelding uploaden",
    image: {
      title: "Upload een bedrijfsafbeelding",
      note: "3 afbeeldingen vereist",
    },
    certi: {
      title: "Upload noodzakelijke certificaten",
      note: "u kunt noodzakelijke certificaten uploaden zoals voedselvergunning, verkoopvergunning, brandveiligheidscertificaat, enz.",
      pl: "Voer certificaatnaam in",
      Required: "Verplicht",
    },
  },
  step5: {
    title: "5. Lidmaatschapskeuze",
    member: {
      title: "Selecteer hieronder het lidmaatschap.",
      6: "6 maanden",
      12: "12 maanden",
    },
    discount: {
      title: "Voer kortingscode",
      pl: "Voer kortingscode in",
    },
    total: "Totaal",
    button: "Versturen",
    required: "Verplicht",
    congrats: "Herzlichen Glückwunsch! Sie sind erfolgreich Partner geworden.",
  },
};
