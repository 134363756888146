import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_GET2, API_GET_USERID } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const Cards = () => {
    const { t } = useTranslation();
    let parseData = JSON.parse(localStorage.getItem("Fiesta"));
    let id = parseData?.id;
    const [compId, setCompId] = useState("")
    const [data, setData] = useState({});

    const fetchData = async (id) => {
        try
        {
            const res = await API_GET2(`${API_endpoints.getbookinganalytics}?company_id=${id}`)
            setData(res?.r)
        } catch (error)
        {
            console.log(error)
        }
    }

    const handleUserData = async () => {
        try
        {
            let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
            setCompId(res?.r?.company_details?.id)
            if (res.s)
            {
                fetchData(res?.r?.company_details?.id)
            }
        } catch (error)
        {
            console.log(error);
        }
    };

    useEffect(() => {
        handleUserData();
    }, []);
    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
                flexDirection: { xs: "column", md: "row" },
            }}
        >
            {/* total */}
            <Box
                sx={{
                    width: "100%",
                    menWidth: "150px",
                    overflow: "scroll",
                    height: `100px`,
                    padding: "10px 14px 10px 14px",
                    borderRadius: `12px`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",

                    background: "#FFF6ED",
                    border: "1px solid #F57C0080",
                }}
            >
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#F57C00",
                    }}
                >
                    {t("ownerProfile.menu.dash.cards.total")}
                </Box>
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#F57C00",
                    }}
                >
                    {data?.total_booking}
                </Box>
            </Box>

            {/* Completed */}
            <Box
                sx={{
                    width: "100%",
                    menWidth: "150px",
                    overflow: "scroll",
                    height: `100px`,
                    padding: "10px 14px 10px 14px",
                    borderRadius: `12px`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: "#4BB54328",
                    border: "1px solid #4BB54380",
                }}
            >
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#4BB543",
                    }}
                >
                    {t("ownerProfile.menu.dash.cards.complete")}
                </Box>
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#4BB543",
                    }}
                >
                    {data?.approved_booking}
                </Box>
            </Box>

            {/* Pending */}
            <Box
                sx={{
                    width: "100%",
                    menWidth: "150px",
                    overflow: "scroll",
                    height: `100px`,
                    padding: "10px 14px 10px 14px",
                    borderRadius: `12px`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: "#F2F7FF28",
                    border: "1px solid #164FA180",
                }}
            >
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#164FA1",
                    }}
                >
                    {t("ownerProfile.menu.dash.cards.pending")}
                </Box>
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#164FA1",
                    }}
                >
                    {data?.pending_booking}
                </Box>
            </Box>
            {/* Cancelled */}
            <Box
                sx={{
                    width: "100%",
                    menWidth: "150px",
                    overflow: "scroll",
                    height: `100px`,
                    padding: "10px 14px 10px 14px",
                    borderRadius: `12px`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: "#FF180014",
                    border: "1px solid #FF180080",
                }}
            >
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#FF1800",
                    }}
                >
                    {t("ownerProfile.menu.dash.cards.cancel")}
                </Box>
                <Box
                    sx={{
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 700,
                        color: "#FF1800",
                    }}
                >
                    {data?.canclled_booking}
                </Box>
            </Box>
        </Box>
    );
};

export default Cards;
