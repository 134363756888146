import { Box, Typography, useTheme } from "@mui/material";

const ProfileCard = () => {
    const theme = useTheme()
    let img = "https://via.placeholder.com/40";
    return (
        <Box

            sx={{
                width: "100%",
                p: 1,
                py: 1.5, borderRadius: '7px',
                display: "flex",
                gap: 1,
                background: "#fff",
                // borderBottom: '1px solid #00000014',
                '&:hover': {
                    background: '#FFF6ED',
                    cursor: 'pointer',
                    outline: '1px solid #F57C00'
                }
            }}
        >
            <Box sx={{ height: "100%", width: "fit-content" }} className="flexCenter">
                <img
                    src={img}
                    style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                    alt=""
                />
            </Box>
            <Box
                sx={{
                    height: "100%",
                    width: "calc(100% - 40px)",
                    display: "flex",
                    gap: 0.5,
                    justifyContent: "space-between",
                    alignItems: "start",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        gap: 0.5,
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ fontWeight: '600', }}>Dummy Data</Typography>
                    <Typography sx={{ fontSize: '14px' }}>2m</Typography>
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        gap: 0.5,
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ color: '#000000B2', fontSize: '14px' }}> Lorem ipsum dolor sit... ?

                    </Typography>
                    <Box sx={{ fontSize: '12px', color: theme.palette.text.defaultLight, background: theme.palette.background.orange, borderRadius: '50%', px: 0.5 }} className="flexCenter">2</Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileCard;
