import React, { useEffect, useState } from "react";
import BannerImage from "../../Component/BannerImage/BannerImage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { API_GET_OPEN } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { CircularProgress } from "@mui/material";

const Faq = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };
  //   const [initialData, setInitialData] = useState([]);
  const handlFaq = async () => {
    try {
      setLoading(true);
      let res = await API_GET_OPEN(API_endpoints.getfaq);
      setLoading(true);
      setData(res.r);
      // setInitialData(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handlFaq();
  }, []);
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    <Box>
      <BannerImage path={t("faq.faq")} />
      <Box>
        <Box
          sx={{ p: { xs: 3, md: 8 }, background: theme.palette.background.gd }}
        >
          <Box sx={{}}>
            <Box
              sx={{
                borderLeft: 0,
                borderRight: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: "90%",
                mx: "auto",
                border: {
                  xs: "none",
                },
                borderTop: "0px",
                borderBottom: "0px",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  color: theme.palette.text.defaultDark,
                  width: { xs: "100%", md: "75%" },
                  m: "auto",
                  borderRadius: "10px",
                  textAlign: "left",
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  className="fontSnugle"
                  sx={{
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: { xs: "22px", md: "28px" },
                    mb: 2,
                  }}
                >
                  {t("faq.faq")}
                </Typography>
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <Accordion
                      key={index}
                      expanded={expandedAccordion === `panel${index + 1}`}
                      onChange={handleChange(`panel${index + 1}`)}
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        m: "auto",
                        mt: 1,
                        color: theme.palette.text.defaultDark,
                        borderRadius: "14px",
                        bgcolor:
                          expandedAccordion === `panel${index + 1}`
                            ? theme.palette.text.defaultLight
                            : "transparent",
                      }}
                    >
                      <AccordionSummary
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "&.MuiAccordionSummary-root": {},
                        }}
                      >
                        {expandedAccordion === `panel${index + 1}` ? (
                          <KeyboardArrowUpIcon
                            sx={{
                              marginRight: 2,
                              color: theme.palette.text.active,
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ marginRight: 2 }} />
                        )}
                        <Typography
                          sx={{
                            color: "black",
                            fontWeight: 500,
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "18px",
                              xl: "20px",
                            },
                          }}
                        >
                          {`${index + 1}. ${item.question}`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "14px",
                              xl: "16px",
                            },
                            paddingLeft: "32px",
                          }}
                        >
                          {item.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;
