import { Box, useTheme, Typography } from "@mui/material";
import BannerImage from "../../Component/BannerImage/BannerImage";
import { useTranslation } from "react-i18next";
import about from "../../Assets/images/about.webp";
import about2 from "../../Assets/images/about2.webp";
import media from "../../Assets/images/media.png";
import MenuSection from "../HomePage/Sections/MenuSection";
import CounterSction from "./CounterSction";
import sideimg1 from "../../Assets/images/strip1.png";
import "./about.css";
import { useEffect } from "react";

const AboutUsScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    <Box>
      <BannerImage path={t("about.aboutus")} />
      <Box
        sx={{
          background: theme.palette.background.gd,
          width: "100%",
          position: "relative"
        }}
      >
        <Box
          sx={{ width: "90%", m: "auto", maxWidth: "1400px" }}
          className="flexCenter"
        >
          <Box className="flexCenter" sx={{ gap: 5, py: 5, width: "100%" }}>
            {/* Left Side Content */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                animation: "slideInLeft 1s ease-in-out",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "90%", lg: "70%" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "28px", sm: "32", md: "33px", lg: "38px" },
                  }}
                >
                  {t("about.headline")}
                </Typography>
                <Box sx={{ border: `1px solid #823D0C24`, width: "100px", my: 1 }} />
                <Typography sx={{ fontWeight: "500" }}>
                  {t("about.subline1")}
                </Typography>
                <Typography sx={{ fontWeight: "500" }}>
                  {t("about.subline2")}
                </Typography>
              </Box>
            </Box>

            {/* Right Side Image */}
            <Box
              className="flexCenter"
              sx={{
                display: { xs: "none", md: "block" },
                width: { xs: "100%", md: "50%" },
                animation: "slideInRight 1s ease-in-out",
              }}
            >
              <img
                src={about}
                height={"100%"}
                width={"100%"}
                style={{ maxHeight: "450px", maxWidth: "450px" }}
                alt=""
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
          <img src={sideimg1} alt="" height={40} />
        </Box>
      </Box>
      <Box
        sx={{
          background: `url(${about2}) center center / cover`,
          height: { xs: "300px", md: "450px" },
          width: "100%",
          flexDirection: "column",
          gap: 5,
        }}
        className="flexCenter"
      >
        <Box
          sx={{
            width: { xs: "80%", sm: "75%", md: "60%", lg: "50%", xl: "40%" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", md: "30px", lg: "38px", xl: "42px" },
              fontWeight: 700,
              textAlign: "center",
              color: theme.palette.text.primary,
            }}
          >
            {t("about.mediaTitle")}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            height: { xs: "60px", md: "80px" },
            width: { xs: "60px", md: "80px" },
          }}
        >
          <img src={media} height={"100%"} width={"100%"} alt="" />
          <Box
            className="mediaAnimate"
            sx={{
              background: "#F57C0024",
              height: "100%",
              width: "100%",
              borderRadius: "50%",
              position: "absolute",
              top: 0,
            }}
          ></Box>
        </Box>
      </Box>
      <CounterSction />
      <MenuSection title={t("about.menu.title")} />

    </Box>
  );
};

export default AboutUsScreen;
