import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataListingComponent } from "../../../Component/DataGridComponent";
import { useEffect, useState } from "react";
import { API_endpoints } from "../../../API/API_Endpoints";
import { API_GET } from "../../../API/config";
let PAGE_SIZE_OPTIONS = [15];
const MyPaymentTab = () => {
  const [data, setData] = useState([])
  const { t } = useTranslation();
  const [IsLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);

  const column = [
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "#",
      width: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "image",
      headerName: `${t("ownerProfile.menu.payment.grid.Image")}`,
      width: 100,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {/* Render image */}
          <img
            src={params.value}
            alt="Profile"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%", // Optional: display as circle
              objectFit: "cover",
            }}
          />
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: `${t("ownerProfile.menu.payment.grid.name")}`,
      minWidth: 150,

      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "amount",
      headerName: `${t("ownerProfile.menu.payment.grid.amount")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      headerClassName: "super-app-theme--header",
      field: "paymentDate",
      headerName: `${t("ownerProfile.menu.payment.grid.Date")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
  ];

  const fetchData = async (id) => {
    try
    {
      let res = await API_GET(
        `${API_endpoints.getOwnerSubscriptionList}`
      );
      if (res?.s)
      {
        const rowId = res?.r?.map((x, i) => {
          return {
            rowId: i + 1,
            ...x,
          };
        });
        setData(rowId);
        setTotalData(rowId?.length)
      }
    } catch (error)
    {
      console.log(error);
    }
  };


  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.menu.payment.note")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} />
      <Box sx={{ height: '300px' }}>
        <DataListingComponent
          data={data ?? []}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={IsLoading}
          totalData={totalData}
          page={page}
          Footer={true}
          hidefooter={true}
          columns={column}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default MyPaymentTab;
