import BannerImage from "../../Component/BannerImage/BannerImage";
import { Box, Grid, useTheme, Typography } from "@mui/material";
import TruckCard from "../HomePage/Sections/Cards/TruckCard";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchSection from "../../Pages/HomePage/Sections/SearchSection";
import { API_GET_OPEN } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import Noresult from "../../Component/Noresultfound/Noresult";
import { useTranslation } from "react-i18next";

const FoodTruckScreen = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const location = useLocation();
  const { t } = useTranslation()

  useEffect(() => {
    if (location.state && location.state.data)
    {
      setData(location.state.data);
    } else
    {
      getData();
    }
  }, [location.state]);

  const getData = async () => {
    try
    {
      let res = await API_GET_OPEN(API_endpoints.getalltrucks);
      setData(res.r);
    } catch (error)
    {
      console.log(error);
    }
  };
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  return (
    <Box>
      {/* Banner Section */}
      <BannerImage path={"Food Truck"} />
      <Box
        sx={{
          background: theme.palette.background.gd,
        }}
      >
        <SearchSection values={location?.state?.formValues || {}} />
        <Box
          sx={{
            width: "90%",
            maxWidth: "1440px",
            m: "auto",
          }}
        >
          <Box sx={{ pb: 5 }}>
            <Grid container spacing={4} sx={{ justifyContent: "center" }}>
              {data?.length ? (
                data?.map((x, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      key={i}
                      sx={{
                        transition: "transform 0.3s ease-in-out",
                      }}
                    >
                      <TruckCard data={x} title={t("myBooking.booknow")} />
                    </Grid>
                  );
                })
              ) : (
                <Box>
                  <Typography className="fontSnugle" sx={{ textAlign: "center", fontSize: "22px" }}>
                    No Record Found
                  </Typography>
                  <Noresult />
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
    // <Work/>
  );
};

export default FoodTruckScreen;
