export const AboutUS_EN_trans = {
    aboutus: "About Us",
    headline: "It is a long established fact that a reader will be distracted",
    subline1: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum`,
    subline2: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum`,
    mediaTitle: "It is a long established fact that a reader will be distracted.",
    count: {
        title: "Explore the Food Truck",
        note: "It is a long established fact that a reader will be distracted by the readable",
        card1: "Ordered",
        card2: "Food truck Registered",
        card3: "Food Dishes",
    },
    menu:{
        title: "Explore the Food Truck",
    }
};
export const AboutUS_NL_trans = {
    aboutus: "Over ons",
    headline: "Het is een bekend feit dat een lezer afgeleid zal worden",
    subline1: `Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare inhoud van een pagina bij het bekijken van de opmaak. Het punt van het gebruik van Lorem Ipsum`,
    subline2: `Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare inhoud van een pagina bij het bekijken van de opmaak. Het punt van het gebruik van Lorem Ipsum`,
    mediaTitle: "Het is een bekend feit dat een lezer afgeleid zal worden.",
    count: {
        title: "Ontdek de Foodtruck",
        note: "Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare inhoud",
        card1: "Besteld",
        card2: "Foodtrucks geregistreerd",
        card3: "Gerechten",
    },
    menu: {
        title: "Ontdek de Foodtruck",
    }
};
