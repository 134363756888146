import { Box, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { API_GET_OPEN, API_GET_USERID, API_POST } from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import ButtonComponent from "../../../../Component/Button/Button";
import Loader from "../../../../Component/Loader/Loader";
import { toast } from "react-toastify";

const OwnerCatererTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parsedData?.id;
  const [cateTYPE, setCateTYPE] = useState(0);
  const catType = [
    "Bussiness Caterer",
    "Food Trucks",
    "Barista / Bartender",
    "Other",
  ];

  const initial1 = {
    type: null,
    about: "",
    id: 0,
    user_id: 0,
  };

  const validationSchema1 = Yup.object({
    about: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: initial1,
    validationSchema: validationSchema1,
    onSubmit: async (values) => {
      setloading(true);
      try {
        const res = await API_POST(API_endpoints.updatecaterer, values);
        if (res.s) {
          toast.success(res.m);
        } else {
          toast.error(res.m);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setloading(false);
      }
    },
  });

  const getData = async (id) => {
    try {
      let res = await API_GET_OPEN(
        `${API_endpoints.getcompanybyid}?company_id=${id}`
      );
      formik.setValues({
        type: res?.r?.caterer?.caterer_type,
        about: res?.r?.caterer?.about,
        user_id: res?.r?.user_id,
        id: res?.r?.id,
      });
      setCateTYPE(res?.r?.caterer?.caterer_type - 1);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyId = async () => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s) {
        getData(res?.r?.company_details?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box pb={1}>{t("Type of caterers")}</Box>
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                background: "#DEE6F252",
                width: "100%",
                height: "Hug(55px)px",
                padding: "14px",
                gap: "10px",
                borderRadius: "12px",
                // color: "#164FA1",
                fontWeight: "600",
              }}
            >
              <Typography>{catType[cateTYPE]}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box pb={1}>{t("More about Company")}</Box>
          <TextField
            type="text"
            id="about"
            name="about"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.about}
            error={formik.touched.about && Boolean(formik.errors.about)}
            helperText={formik.touched.about && formik.errors.about}
            variant="outlined"
            placeholder={t("becomePartner.step2.yourSelf.pl")}
            multiline
            fullWidth
            rows={5}
            InputProps={{
              sx: {
                borderRadius: "12px",
                color: theme.palette.text.defaultDark,
                "&:hover": {
                  color: theme.palette.text.active,
                  bgcolor: theme.palette.background.inputBg,
                  borderRadius: "12px",
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box className="flexCenter" sx={{ mt: 5 }}>
        <ButtonComponent
          disabled={loading}
          type="button"
          label={
            loading ? <Loader /> : `${t("ownerProfile.menu.profile.update")}`
          }
          onClick={formik.handleSubmit}
          sx={{
            width: { xs: "80%", sm: "130px" },
            background: theme.palette.background.btn,
            color: theme.palette.text.primary,
          }}
        />
      </Box>
    </>
  );
};

export default OwnerCatererTab;
