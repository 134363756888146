import { Box } from "@mui/material";
import ChatHeader from "./ChatHeader";
import MessageHandler from "./MessageHandler";
import ChatFooter from "./ChatFooter";

const ChatMessageComponent = () => {
  return (
    <Box
      className="flexCenter"
      sx={{
        width: { xs: "100%", md: `calc(100% - 280px)` },
        flexDirection: "column",
        gap: 1,
        background: "#F5F5F5",
        height: "100%",
        p: 1,
        borderRadius: "7px",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          mx: 1,
          background: "white",
          borderRadius: "7px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          p: 2,
        }}
      >
        <ChatHeader />
        <MessageHandler />
        <ChatFooter />
      </Box>
    </Box>
  );
};

export default ChatMessageComponent;
