export const Register_EN_Trans = {
  register: "Register",
  email: "Enter Email",
  password: "Enter Password",
  repssword: "Re-Enter your password",
  understand: "I Understand the",
  terms: "Terms of Service",
  and: "and",
  privacy: " Privacy Policy.",
  already: "Already have an account?",
  fullname: "Enter your full name",
  number: "Enter your number",
  selectcountry: "Select your country",
  loginhere: "Login here",
  profileImageRequired: "Profile Image is required",
  invalidEmail: "Invalid email",
  emailRequired: "Email is required",
  passwordRequired: "Password is required",
  passwordMin: "Password must be at least 6 characters.",
  passwordMax: "Password must be at most 20 characters.",
  confirmPasswordRequired: "Confirm password is required",
  passwordsMustMatch: "Passwords must match",
  countryRequired: "Country is required",
  validCountry: "Please select a valid country.",
  fullNameRequired: "Full name is required",
  dobRequired: "Date of birth is required",
  numberRequired: "Number is required",
  onlyNumbers: "Only numbers are allowed",
  mustAgreeToTerms: "You must agree to the terms and conditions",
  selectmeals:"Select Meals"
};

export const Register_DE_Trans = {
  register: "Registrieren",
  email: "E-Mail eingeben",
  password: "Passwort eingeben",
  repssword: "Passwort erneut eingeben",
  understand: "Ich verstehe die",
  terms: "Nutzungsbedingungen",
  and: "und",
  privacy: "Datenschutzbestimmungen.",
  already: "Haben Sie bereits ein Konto?",
  fullname: "Geben Sie Ihren vollständigen Namen ein",
  number: "Geben Sie Ihre Telefonnummer ein",
  selectcountry: "Wählen Sie Ihr Land aus",
  loginhere: "Hier einloggen",
  profileImageRequired: "Profilbild ist erforderlich",
  invalidEmail: "Ungültige E-Mail",
  emailRequired: "E-Mail ist erforderlich",
  passwordRequired: "Passwort ist erforderlich",
  passwordMin: "Das Passwort muss mindestens 6 Zeichen lang sein.",
  passwordMax: "Das Passwort darf höchstens 20 Zeichen lang sein.",
  confirmPasswordRequired: "Passwortbestätigung ist erforderlich",
  passwordsMustMatch: "Passwörter müssen übereinstimmen",
  countryRequired: "Land ist erforderlich",
  validCountry: "Bitte wählen Sie ein gültiges Land aus.",
  fullNameRequired: "Vollständiger Name ist erforderlich",
  dobRequired: "Geburtsdatum ist erforderlich",
  numberRequired: "Nummer ist erforderlich",
  onlyNumbers: "Es sind nur Zahlen erlaubt",
  mustAgreeToTerms: "Sie müssen den Geschäftsbedingungen zustimmen",
  selectmeals:"Mahlzeiten auswählen"
};
