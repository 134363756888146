import { useState, useRef, useEffect } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { API_endpoints } from "../../API/API_Endpoints";

function Slider({ data }) {
  const [activeImage, setActiveImage] = useState(0);
  const theme = useTheme();
  const thumbnailsRef = useRef([]); // Ref array to store all thumbnail elements

  const handleNext = () => {
    setActiveImage((prevIndex) =>
      prevIndex === data?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setActiveImage((prevIndex) =>
      prevIndex === 0 ? data?.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const handleThumbnailClick = (index) => {
    setActiveImage(index);
  };

  useEffect(() => {
    // Scroll the active thumbnail into view, centered
    if (thumbnailsRef.current[activeImage])
    {
      thumbnailsRef.current[activeImage].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeImage]);

  return (
    <Box sx={{ position: "relative" }}>
      {/* Main Image */}
      <Box
        {...handlers}
        sx={{
          width: "100%",
          mb: 2,
          overflow: "hidden",
          position: "relative",
          p: 1,
        }}
      >
        <Box sx={{ height: { xs: "350px", md: '450px' } }}>
          {data && data[activeImage]?.image && (
            <img
              src={API_endpoints.baseURL + "/" + data[activeImage]?.image}
              height={"100%"}
              width={"100%"}
              alt="main"
              style={{ objectFit: "cover", borderRadius: "8px" }}
            />
          )}
        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          {/* Prev Button for Main Image */}
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
              bgcolor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 12px",
            }}
            onClick={handlePrev}
          >
            {"<"}
          </IconButton>

          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              bgcolor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 12px",
            }}
            onClick={handleNext}
          >
            {">"}
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          position: "relative",
          display: data?.length > 1 ? { xs: "none", md: "flex" } : "none",

          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handlePrev}>
          <ArrowCircleLeftOutlinedIcon style={{ fontSize: "30px" }} />
        </IconButton>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexWrap: "nowrap",
            gap: 2,
            height: { md: "120px", lg: "100px", xl: "120px" },
            width: "90%",
            justifyContent: "start",
            alignItems: "center",
            overflowX: "auto",
            position: "relative",
          }}
        >
          {data?.map((e, index) => (
            <Box
              key={index}
              ref={(el) => (thumbnailsRef.current[index] = el)}
              sx={{
                minWidth: { md: "200px", lg: "160px", xl: "180px" },
                height: "100%",
                cursor: "pointer",
                borderRadius: "8px",
                border:
                  activeImage === index
                    ? `3px solid ${theme.palette.text.active}`
                    : "2px solid transparent",
                transition: "border-color 0.3s ease-in-out",
              }}
              onClick={() => handleThumbnailClick(index)}
            >
              <img
                src={API_endpoints?.baseURL + "/" + e?.image}
                height={"100%"}
                width={"100%"}
                alt={`thumbnail-${index}`}
                style={{ borderRadius: "8px" }}
              />
            </Box>
          ))}
        </Box>

        <IconButton onClick={handleNext}>
          <ArrowCircleRightOutlinedIcon style={{ fontSize: "30px" }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Slider;
