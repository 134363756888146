import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
  IconButton,
  InputAdornment,
  Autocomplete,
  Paper,
} from "@mui/material";
import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import login_bg_truck from "../../../Assets/images/login_bg_truck.png";
import login_bg_lines from "../../../Assets/images/login_bg_lines.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled/macro";
import { API_GET, API_POST } from "../../../API/config";
import { toast } from "react-toastify";
import SingleImage from "../../../Component/ImageUploader/SingleImage";
import { API_endpoints } from "../../../API/API_Endpoints";
import CustomPhoneNumber from "../../../Component/Input/PhoneInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../../Component/Loader/Loader";

const Register = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowRePassword = () => setShowRePassword(!showRePassword);
  const [loading, setLoading] = useState(false);
  const InputWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    padding: "18px",
    borderRadius: "12px",
    border:
      formik.touched.dob && formik.errors.dob
        ? "1px solid red"
        : "1px solid #1A130C3D",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      border:
        formik.touched.dob && formik.errors.dob
          ? "1px solid red"
          : `1px solid ${theme.palette.background.lightorange}`,
      backgroundColor: theme.palette.background.inputBg,
    },
    '& input[type="date"]': {
      width: "100%",
      border: "none",
      backgroundColor: "transparent",
      color: theme.palette.text.defaultDark,
      padding: "0",
      margin: "0",
      "&:focus": {
        outline: "none",
      },
    },
  }));

  const handleCheckboxChange = () => {
    formik.setFieldValue("agree", !formik.values.agree);
  };

  const handleCountry = async () => {
    try {
      let res = await API_GET(API_endpoints.countries);
      setCountries(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCountry();
  }, []);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const formik = useFormik({
    initialValues: {
      profile: "",
      email: "",
      password: "",
      repassword: "",
      country: 0,
      agree: false,
      fullname: "",
      dob: "",
      cc: "",
      phno: "",
    },
    validationSchema: Yup.object({
      profile: Yup.string().required(t("Register.profileImageRequired")),
      email: Yup.string()
        .matches(emailRegex, t("Register.invalidEmail"))
        .required(t("Register.emailRequired")),
      password: Yup.string()
        .required(t("Register.passwordRequired"))
        .min(6, t("Register.passwordMin"))
        .max(20, t("Register.passwordMax")),
      repassword: Yup.string()
        .required(t("Register.confirmPasswordRequired"))
        .oneOf([Yup.ref("password")], t("Register.passwordsMustMatch")),
      country: Yup.string()
        .required(t("Register.countryRequired"))
        .notOneOf(["0"], t("Register.validCountry")),
      fullname: Yup.string().required(t("Register.fullNameRequired")),
      dob: Yup.date().required(t("Register.dobRequired")).nullable(),
      phno: Yup.string()
        .required(t("Register.numberRequired"))
        .matches(/^[0-9]+$/, t("Register.onlyNumbers")),
      agree: Yup.boolean().oneOf([true], t("Register.mustAgreeToTerms")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const f = new FormData();
      f.append("full_name", formik.values.fullname);
      f.append("country_id", formik.values.country);
      f.append("profile_img", formik.values.profile);
      f.append("dob", formik.values.dob);
      f.append("email", formik.values.email);
      f.append("phno_cc", "+91"); // adjust this as needed
      f.append("phno", formik.values.phno);
      f.append("password", formik.values.password);
      try {
        const res = await API_POST(API_endpoints.register, f);
        if (res.s) {
          setLoading(false);
          toast.success(res.m);
          navigate("/login");
        } else {
          setLoading(false);
          toast.error(res.m);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
 
  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, #FFEDDD 0%, #F5F5F5 100%)`,
        height: { xs: "", md: "calc(100vh - 66px)" },
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: { xs: 3, md: 2 },
      }}
    >
      <Box sx={{ position: "absolute", bottom: -5, left: 0 }}>
        <img
          style={{ width: "150px", height: "150px" }}
          src={login_bg_truck}
          alt="preview"
        />
      </Box>
      <Box sx={{ position: "absolute", top: 50, right: 0 }}>
        <img
          style={{ width: "120px", height: "35px" }}
          src={login_bg_lines}
          alt="preview"
        />
      </Box>
      {/* main content */}
      <Box
        sx={{
          width: { xs: "100%", sm: "90%", md: "70%" },
          // height: "calc(100vh - 66px)",
          zIndex: 1,
          textAlign: "center",
          display: "flex",
          gap: 1,
          flexDirection: "column",
          p: 2,
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{ fontSize: { xs: "24px", md: "26px", lg: "30px" } }}
        >
          {t("Register.register")}
        </Typography>
        {/* White box */}
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            bgcolor: theme.palette.background.main,
            borderRadius: "20px",
            p: { xs: 2, xl: 5 },
            px: 5,
            height: {
              xs: "",
              md: "calc(100vh - 180px)",
              xl: "calc(100vh - 300px)",
            },
            overflow: { xs: "", md: "auto" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 2,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "90px",
                height: "90px",
                cursor: "pointer",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <img
                style={{ width: "100%", height: "100%" }}
                src={add_profile_pic}
                alt="preview"
              /> */}
              <SingleImage
                // apiPreview={
                //   typeof formik.values.image === "string"
                //     ? BaseUrl + formik.values.image
                //     : null
                // }
                setImage={formik.setFieldValue}
                name="profile"
              />
            </Box>{" "}
            {formik.errors.profile ? (
              <Typography sx={{ color: "red", fontSize: "14px" }}>
                {formik.errors.profile}
              </Typography>
            ) : null}
          </Box>

          <Box pb={1}>
            <FormControl
              fullWidth
              error={formik.touched.country && Boolean(formik.errors.country)}
              sx={{
                color: theme.palette.text.defaultDark,
                textAlign: "start",
                borderRadius: "12px",
              }}
            >
              <Autocomplete
                sx={{
                  borderRadius: "12px",
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                  },
                  "&:active": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                  },
                }}
                id="country"
                name="country"
                options={countries || []}
                getOptionLabel={(option) => option.country_name || ""}
                value={
                  countries.find((c) => c.id === formik.values.country) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("country", newValue?.id || "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Register.selectcountry")}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: theme.palette.text.defaultDark,
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.text.strip,
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: theme.palette.background.strip, // Focused label color
                      },
                      "& .MuiFormHelperText-root": {
                        color: theme.palette.text.error, // Error text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "12px",
                          borderColor: `1px solid ${theme.palette.background.strip}`,
                        },
                      },
                    }}
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      bgcolor: theme.palette.background.main,
                      color: theme.palette.text.strip,
                    }}
                  >
                    {children}
                  </Paper>
                )}
                ListboxProps={{
                  sx: {
                    bgcolor: theme.palette.background.main, // Background of options list
                    color: theme.palette.text.defaultDark, // Text color of options
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        bgcolor: theme.palette.background.inputBgHover, // Option hover background
                        color: theme.palette.text.active, // Option hover text color
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              alignItems: "center",
              pb: 1,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type="text"
                id="fullname"
                name="fullname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullname}
                error={
                  formik.touched.fullname && Boolean(formik.errors.fullname)
                }
                helperText={formik.touched.fullname && formik.errors.fullname}
                variant="outlined"
                placeholder={t("Register.fullname")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <InputWrapper>
                <input
                  type="date"
                  value={formik.values.dob}
                  onChange={(e) => formik.setFieldValue("dob", e.target.value)}
                  onBlur={formik.handleBlur} // Handle blur to trigger validation
                  // style={{
                  //   border:
                  //     formik.touched.dob && formik.errors.dob
                  //       ? "1px solid red"
                  //       : "",
                  // }}
                />
              </InputWrapper>{" "}
              {formik.touched.dob && formik.errors.dob && (
                <div
                  style={{
                    color: theme.palette.text.error,
                    fontSize: "12px",
                    textAlign: "start",
                  }}
                >
                  {formik.errors.dob}
                </div>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              alignItems: "center",
              pb: 1,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                placeholder={t("Login.email")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <CustomPhoneNumber
                name="phno"
                theme={theme}
                country={"us"}
                placeholder="Enter your Number"
                value={formik.values.cc + formik.values.phno}
                onChange={(value, country) => {
                  const countryCodeLength = country.dialCode.length;
                  const phon_cc = value.slice(0, countryCodeLength);
                  const phno = value.slice(countryCodeLength);
                  formik.setFieldValue("phno", phno);
                  formik.setFieldValue("cc", phon_cc);
                }}
                inputClass="inputClassCountry"
              />

              {formik.touched.phno && formik.errors.phno && (
                <div
                  style={{
                    color: "red",
                    marginTop: "2px",
                    textAlign: "left",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.phno}
                </div>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              alignItems: "center",
              pb: 1,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                placeholder={t("Login.password")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            sx={{ color: theme.palette.text.active }}
                          />
                        ) : (
                          <Visibility
                            sx={{ color: theme.palette.text.active }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                type={showRePassword ? "text" : "password"}
                id="repassword"
                name="repassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.repassword}
                error={
                  formik.touched.repassword && Boolean(formik.errors.repassword)
                }
                helperText={
                  formik.touched.repassword && formik.errors.repassword
                }
                variant="outlined"
                placeholder={t("Register.repssword")}
                InputProps={{
                  sx: {
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRePassword}
                      >
                        {showRePassword ? (
                          <VisibilityOff
                            sx={{ color: theme.palette.text.active }}
                          />
                        ) : (
                          <Visibility
                            sx={{ color: theme.palette.text.active }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box>
              <FormControl
                error={formik.touched.agree && Boolean(formik.errors.agree)} // Error state
                component="fieldset"
                sx={{ mb: 2 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      id="agree"
                      name="agree"
                      checked={formik.values.agree}
                      onChange={formik.handleChange} // Formik handleChange for updates
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: theme.palette.text.defaultDark,
                        fontSize: { xs: "12px", md: "14px" },
                        textAlign: "left",
                      }}
                    >
                      {t("Register.understand")} &nbsp;
                      <Link
                        to="/terms"
                        style={{
                          color: theme.palette.text.active,
                          textDecoration: "underline",
                          fontSize: { xs: "12px", md: "14px" },
                        }}
                      >
                        {t("Register.terms")}
                      </Link>{" "}
                      {t("and")}
                      <Link
                        to="/privacy"
                        style={{
                          color: theme.palette.text.active,
                          textDecoration: "underline",
                          fontSize: { xs: "12px", md: "14px" },
                        }}
                      >
                        {t("Register.privacy")}
                      </Link>
                    </Typography>
                  }
                />
                {formik.touched.agree && formik.errors.agree && (
                  <FormHelperText>{formik.errors.agree}</FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 1,
              gap: 1.5,
              pb: 2,
            }}
          >
            <ButtonComponent
              disabled={loading}
              type="button"
              onClick={formik.handleSubmit}
              label={loading ? <Loader /> : t("Register.register")}
              sx={{
                width: { xs: "80%", sm: "40%", md: "20%" },
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />

            <Typography
              sx={{
                color: theme.palette.text.defaultDark,
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              {t("Register.already")}  &nbsp;
              <Link
                to="/login"
                style={{
                  color: theme.palette.text.active,
                  textDecoration: "underline",
                }}
              >
                {t("Register.loginhere")}
              </Link>{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
