import React, { useState, useRef } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BecomeAPartnerStepper from "../../Component/Stepper/BecomeAPartnerStepper";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import WestRoundedIcon from "@mui/icons-material/WestRounded";

const BecomeAPartnerScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(1);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const step5Ref = useRef(null);

  const fetchStep1Data = async () => {
    const formik = step1Ref.current?.Formik;
    if (formik) {
      const savedData = JSON.parse(localStorage.getItem("Step1Data"));
      if (savedData) {
        formik.setValues(savedData);
      }
    }
  };

  const fetchStep2Data = async () => {
    const formik = step2Ref.current?.Formik;
    if (formik) {
      const savedData = JSON.parse(localStorage.getItem("Step2Data"));
      if (savedData) {
        formik.setValues(savedData);
      }
    }
  };

  const fetchStep3Data = async () => {
    const formik = step3Ref.current?.Formik;
    if (formik) {
      const savedData = JSON.parse(localStorage.getItem("Step3Data"));
      if (savedData) {
        formik.setValues(savedData);
      }
    }
  };

  const fetchStep4Data = async () => {
    const formik = step4Ref.current?.Formik;
    if (formik) {
      const savedData = JSON.parse(localStorage.getItem("Step4Data"));
      if (savedData) {
        formik.setValues(savedData);
      }
    }
  };

  const fetchStep5Data = async () => {
    const formik = step5Ref.current?.Formik;
    if (formik) {
      const savedData = JSON.parse(localStorage.getItem("Step5Data"));
      if (savedData) {
        formik.setValues(savedData);
      }
    }
  };

  const handlePrev = async () => {
    switch (activeStep) {
      case 1:
        await fetchStep1Data();
        break;
      case 2:
        await fetchStep2Data();
        break;
      case 3:
        await fetchStep3Data();
        break;
      case 4:
        await fetchStep4Data();
        break;
      case 5:
        await fetchStep5Data();
        break;
      default:
        break;
    }
    setActiveStep((prev) => prev - 1);
  };
  const handleNext = async () => {
    switch (activeStep) {
      case 1:
        await handleStep1Logic();
        break;
      case 2:
        await handleStep2Logic();
        break;
      case 3:
        await handleStep3Logic();
        break;
      case 4:
        await handleStep4Logic();
        break;
      case 5:
        await handleStep5Logic();
        break;
      default:
        break;
    }
  };

  const handleStepLogic = async (stepRef, stepNumber) => {
    const formik = stepRef.current?.Formik;

    if (formik) {
      await formik.submitForm();
      await formik.validateForm();
      if (formik.isValid && Object.keys(formik.errors).length === 0) {
        if (activeStep != 2 && formik.dirty) {
          setActiveStep((prev) => prev + 1);
        } else if (activeStep == 2 && formik.values.caterer_type != 0) {
          setActiveStep((prev) => prev + 1);
        }
      } else {
        console.log(
          `Form validation failed in Step ${stepNumber}`,
          formik.errors
        );
      }
    } else {
      console.log(`Formik instance not found in Step ${stepNumber}`);
    }
  };

  const handleStep1Logic = () => handleStepLogic(step1Ref, 1);
  const handleStep2Logic = () => handleStepLogic(step2Ref, 2);
  const handleStep3Logic = () => handleStepLogic(step3Ref, 3);
  const handleStep4Logic = () => handleStepLogic(step4Ref, 4);
  const handleStep5Logic = () => handleStepLogic(step5Ref, 5);

  return (
    <Box
      sx={{
        background: theme.palette.background.section,
        width: "100%",
        minHeight: "calc(100vh - 390px)",
      }}
    >
      <Box
        sx={{
          width: "90%",
          m: "0 auto !important",
          py: 3,
          maxWidth: "1400px",
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{
            color: theme.palette.text.darkOrange,
            fontSize: {
              xs: "28px",
              md: "24px",
              lg: "28px",
              xl: "35px",
            },
          }}
        >
          {activeStep !== 2
            ? t("becomePartner.title1")
            : t("becomePartner.title2")}
        </Typography>
        <BecomeAPartnerStepper activeStep={activeStep - 1} totalSteps={5} />
        <Box>
          {activeStep === 1 ? (
            <Step1 ref={step1Ref} />
          ) : activeStep === 2 ? (
            <Step2 ref={step2Ref} />
          ) : activeStep === 3 ? (
            <Step3 ref={step3Ref} />
          ) : activeStep === 4 ? (
            <Step4 ref={step4Ref} />
          ) : (
            activeStep === 5 && <Step5 ref={step5Ref} />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            p: 3,
          }}
        >
          <button
            className={activeStep !== 1 && "btnHover"}
            disabled={activeStep === 1}
            style={{
              background: "transparent",
              border: "none",
              cursor: activeStep !== 1 ? "pointer" : "default",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
            onClick={() => handlePrev()}
          >
            <WestRoundedIcon /> {t("becomePartner.prev")}
          </button>

          <button
            className={activeStep !== 5 && "btnHover"}
            disabled={activeStep === 5}
            style={{
              background: "transparent",
              border: "none",
              cursor: activeStep !== 5 ? "pointer" : "default",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
            onClick={handleNext}
          >
            {t("becomePartner.next")} <EastRoundedIcon />
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default BecomeAPartnerScreen;
