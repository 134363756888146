import {
  Box,
  TextField,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  Autocomplete,
  Paper,
  Chip,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import { API_GET, API_GET_OPEN } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Component/Loader/Loader";

const SearchSection = ({ values }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [meal, setMeal] = useState([]);
  const [diet, setDiet] = useState([]);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      zipCode: "",
      meal: [],
      diet: [],
    },
    validationSchema: Yup.object({
      meal: Yup.array()
        .min(0, `${t("search.validate.food")}`)
        .nullable(),

      zipCode: Yup.number()
        .typeError(`${t("search.validate.code")}`)
        .nullable(),

      diet: Yup.array()
        .min(0, `${t("search.validate.veg")}`)
        .nullable(),
    }).test("at-least-one", `${t("at least one")}`, function (value) {
      const { meal, zipCode, diet } = value;

      const isMealFilled = meal && meal.length > 0;
      const isDietFilled = diet && diet.length > 0;
      const isZipCodeFilled =
        zipCode !== null && zipCode !== undefined && zipCode !== "";

      return isMealFilled || isDietFilled || isZipCodeFilled;
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let apiUrl = `${API_endpoints.getalltrucks}?count=${count}&offset=30`;

      try {

        if (values?.meal?.length > 0) {
          apiUrl += `&meal_ids=${values.meal.join(",")}`;
          console.log("oho1");
        }

        if (values?.diet?.length > 0) {
          apiUrl += `&diet_ids=${values.diet.join(",")}`;
        } else if (values?.zipCode) {
          apiUrl += `&zip_code=${values.zipCode}`;
        }

        let res = await API_GET_OPEN(apiUrl);

        if (res.s) {
          navigate(NavRoutes.TRUCKS, {
            state: {
              data: res.r,
              formValues: values,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching trucks:", error);
      } finally {
        setLoading(false); // Always called regardless of success or error
      }
    },
  });

  const handleMeal = async () => {
    try {
      let res = await API_GET(API_endpoints.getmealcat);
      setMeal(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiet = async () => {
    try {
      let res = await API_GET(API_endpoints.getdietcat);
      setDiet(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleMeal();
    handleDiet();
  }, []);

  useEffect(() => {
    if (values) {
      formik.setValues(values);
    }
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        background: values ? "transparent" : theme.palette.background.section,
        py: 3,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          width: "90%",
          maxWidth: "1400px",
          marginX: "auto",
          boxShadow: "0px 0px 20px 0px #17120D14",
          borderRadius: "14px",
          padding: 3,
          my: 3,
          bgcolor: theme.palette.background.section,
        }}
      >
        {/* Left section */}
        <Grid className="removeDefault" item xs={12} md={9}>
          <Grid container spacing={2}>
            {/* Meal search input */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={formik.touched.meal && Boolean(formik.errors.meal)}
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Autocomplete
                  multiple
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                  id="meals"
                  name="meal"
                  options={meal || []}
                  getOptionLabel={(option) => option.meal_name || ""}
                  value={
                    meal.filter((m) => formik.values.meal?.includes(m.id)) || []
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "meal",
                      newValue.map((m) => m.id)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Register.selectmeals")}
                      error={formik.touched.meal && Boolean(formik.errors.meal)}
                      helperText={formik.touched.meal && formik.errors.meal}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: theme.palette.text.defaultDark,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.strip,
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.background.strip,
                        },
                        "& .MuiFormHelperText-root": {
                          color: theme.palette.text.error,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "12px",
                            borderColor: `1px solid ${theme.palette.background.strip}`,
                          },
                        },
                      }}
                    />
                  )}
                  // Customize selected tags (chips) style
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.meal_name}
                        {...getTagProps({ index })}
                        sx={{
                          color: theme.palette.text.defaultDark,
                          "& .MuiChip-deleteIcon": {
                            color: "black",
                          },
                        }}
                      />
                    ))
                  }
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.strip,
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  ListboxProps={{
                    sx: {
                      bgcolor: theme.palette.background.main,
                      color: theme.palette.text.defaultDark,
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          bgcolor: theme.palette.background.inputBgHover,
                          color: theme.palette.text.active,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            {/* Zip code input */}
            <Grid item xs={12} md={6}>
              <TextField
                placeholder={t("search.input2")}
                value={formik.values.zipCode}
                type="number"
                name="zipCode"
                id="zipCode"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Checkbox section for diet options */}
          <Grid item xs={12}>
            <FormGroup row sx={{ gap: 1 }}>
              {diet?.length > 0 &&
                diet.map((e, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        id={e.id.toString()}
                        value={e.id}
                        checked={formik.values.diet?.includes(Number(e.id))}
                        onChange={(event) => {
                          const selectedId = Number(event.target.value);
                          const selectedDiet = [...(formik.values.diet || [])];

                          if (event.target.checked) {
                            selectedDiet.push(selectedId);
                          } else {
                            const index = selectedDiet.indexOf(selectedId);
                            if (index > -1) {
                              selectedDiet.splice(index, 1);
                            }
                          }

                          formik.setFieldValue("diet", selectedDiet);
                        }}
                      />
                    }
                    label={e.diet_name}
                    sx={{ color: theme.palette.text.secondary }}
                  />
                ))}
            </FormGroup>

            {/* Error message for diet */}
            {formik.touched.diet && formik.errors.diet && (
              <Box sx={{ color: "#E06B6B", fontSize: "12px" }}>
                {formik.errors.diet}
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Submit button section */}
        <Grid
          item
          xs={12}
          md={3}
          className="removeDefault"
          container
          justifyContent={{ xs: "center", md: "end" }}
          alignItems={"start"}
        >
          <ButtonComponent
            label={loading ? <Loader /> : t("search.btn")}
            sx={{
              background: values
                ? theme.palette.background.brown
                : theme.palette.background.btn,
              color: theme.palette.text.primary,
              width: "180px",
            }}
            onClick={formik.handleSubmit}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchSection;
