export const OwnerProfile_EN_trans = {
  myProfile: "My Profile",
  title: "Partner Dashboard",
  menu: {
    dash: {
      title: "Dashboard",
      note: "Food Truck Booking Information",
      latest: "Latest Bookings",
      cards: {
        total: "Total",
        complete: "Approved",
        pending: "Pending",
        cancel: "Cancelled",
      },
      grid: {
        profile: "Profile",
        booking: "Booking Date",
        name: "Name",
        eDate: "Ending Date",
      },
    },
    profile: {
      title: "My Profile",
      note: "Profile Information",
      reset: "Reset Password",
      company: "Company Images",
      certi: "Essential Certificate",
      update: "Update",
    },
    company: {
      title: "My Company",
      note: "Company Information",
      update: "Update",
    },
    payment: {
      title: "My Subscriptions",
      note: "Subscription Payment History",
      grid: {
        id: " Paypal Id",
        Image: "Image",
        name: "Name",
        amount: "Amount",
        Date: "Payment Date",
        eDate: "Expiry Date"
      },
    },
    review: {
      title: "Customer Reviews",
      note: "Reviews",
      grid: {
        Profile: "Profile",
        name: "Name",
        Review: "Review",
        diplay: "Display Review",
      },
    },
    booking: {
      title: "My Bookings",
      note: "Current Booking",
      grid: {
        Profile: "Profile",
        name: "User Name",
        booking: "Booking Date",
        eDate: "Ending Date",
        view: "View",
        action: "Action",
        ViewDetail: "View Details",
      },
      myBooking: {
        title: "My Booking",
        city: "City Name",
        add: "Address",
        postal: "Postal Code",
        Type: "Type of Diets",
        meal: "Type of Meal",
        date: "Date",
        Contact: "Contact Details",
        person: "Persons",
        budget: "Budget per Person",
        amount: "Paid Amount",
      },
    },
  },
};

export const OwnerProfile_NL_trans = {
  myProfile: "Mijn Profiel",
  title: "Welkom op het Catering Fiesta Board",
  menu: {
    dash: {
      title: "Dashboard",
      note: "Informatie over Food Truck Boekingen",
      latest: "Laatste Boekingen",
      cards: {
        total: "Totaal",
        complete: "goedgekeurd",
        pending: "In Behandeling",
        cancel: "Geannuleerd",
      },
      grid: {
        profile: "Profiel",
        booking: "Boekingsdatum",
        name: "Naam",
        eDate: "Einddatum",
      },
    },
  },
  profile: {
    title: "Mijn Profiel",
    note: "Profiel Informatie",
    reset: "Wachtwoord Resetten",
    company: "Bedrijfsafbeeldingen",
    certi: "Essentieel Certificaat",
    update: "Bijwerken",
  },
  company: {
    title: "Mijn Abonnementen",
    note: "Abonnementsgeschiedenis",
    grid: {
      id: "Id",
      Image: "Afbeelding",
      name: "Naam",
      amount: "Bedrag",
      Date: "Betaaldatum",
    },
  },
  payment: {
    title: "Mijn Abonnementen",
    note: "Abonnementsgeschiedenis",
    grid: {
      id: "Id",
      Image: "Afbeelding",
      name: "Naam",
      amount: "Bedrag",
      Date: "Abonnementsdatum",
    },
  },

  review: {
    title: "Klantbeoordelingen",
    note: "Beoordelingen",
    grid: {
      Profile: "Profiel",
      name: "Naam",
      Review: "Beoordeling",
      diplay: "Beoordeling Weergeven",
    },
  },

  booking: {
    title: "Mijn Boekingen",
    note: "Huidige Boekingen",
    grid: {
      Profile: "Profiel",
      name: "Gebruikersnaam",
      booking: "Boekingsdatum",
      eDate: "Einddatum",
      view: "Weergeven",
      action: "Actie",
      ViewDetail: "Details Weergeven",
    },
    myBooking: {
      title: "Mijn Boeking",
      city: "Stadsnaam",
      add: "Adres",
      postal: "Postcode",
      Type: "Soort Dieet",
      meal: "Type Maaltijd",
      date: "Datum",
      Contact: "Contactgegevens",
      person: "Personen",
      budget: "Budget per Persoon",
      amount: "Betaald Bedrag",
    },
  },
};
