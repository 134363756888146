import { Box } from "@mui/material";
import ProfileCard from "./ProfileCard";

const ChatListComponent = ({ setSelectedChatId }) => {
    return (
        <Box
            sx={{
                width: { xs: "100%", md: "280px" },
                display: "flex",
                flexDirection: "column",
                gap: 1,
                justifyContent: "start",
                alignItems: "center",
                height: "100%",
                overflow: "scroll",
                p: 1,
                background: "#F5F5F5",
            }}
        >
            <ProfileCard />
            <ProfileCard />
            <ProfileCard />
            <ProfileCard />
            <ProfileCard />
        </Box>
    );
};

export default ChatListComponent;
