import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import sideimg1 from "../../../Assets/images/strip1.png";
import { useTranslation } from "react-i18next";
import CateringCard from "./Cards/CateringCard";
import Catering from "../../../Assets/images/cate1.png";
import Cat1 from "../../../Assets/images/cat2.jpg"
import Cat2 from "../../../Assets/images/cat3.jpg"
import ScrollAnimation from 'react-animate-on-scroll';  
const CateringSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const cateringData = [
        {
            id: 1,
            title: "Corporate Catering",
            desc: "Providing professional catering services for corporate events with a variety of menu options.",
            image: Catering,
        },
        {
            id: 2,
            title: "Wedding Catering",
            desc: "Elegant catering services for weddings with customizable menus and themes to suit your special day.",
            image: Cat1,
        },
        {
            id: 3,
            title: "Private Catering",
            desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
            image: Cat2,
        },
    ];

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: "100%",
                position: "relative",
                flexDirection: "column",
                paddingY: { xs: 4, md: 5 },
                gap: 5,
                alignItems: "center"
            }}
        >

            <Grid container spacing={3} sx={{ width: "90%", marginX: "auto", maxWidth: "1400px", my: 1 }}>
                <Grid className="removeDefault" item xs={12}>
                    <Grid

                        container
                        justifyContent="center"
                        sx={{ width: { xs: "100%", sm: "80%", md: "50%", lg: "35%" }, m: "auto" }}
                    >
                        <Grid className="removeDefault" item xs={12}>
                            <Typography
                                variant="h3"
                                className="fontSnugle"
                                sx={{
                                    color: theme.palette.text.dark,
                                    fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                                    textAlign: "center",
                                }}
                            >
                                {t("explore.title")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: theme.palette.text.subHead,
                                    fontSize: { xs: "18px", md: "20px" },
                                    textAlign: "center",
                                    mt: "0.5rem",
                                }}
                            >
                                {t("explore.subtitle")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    className="removeDefault"
                    xs={12}
                    justifyContent="center"
                    spacing={3}
                    sx={{ alignItems: "center", flexWrap: "wrap", }}
                >
                    {cateringData.map((x, i) => {
                        let animationType;
                        if (i === 0)
                        {
                            animationType = "animate__fadeInUp";
                        } else if (i === 1)
                        {
                            animationType = "animate__zoomIn";
                        } else if (i === cateringData.length - 1)
                        {
                            animationType = "animate__fadeInDown";
                        }

                        return (
                            <Grid item xs={12} sm={6} md={4} key={i} spacing={2}>
                                <ScrollAnimation animateIn={animationType}>
                                    <CateringCard data={x} />
                                </ScrollAnimation>
                            </Grid>
                        );
                    })}
                </Grid>

                <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
                    <img src={sideimg1} alt="" height={40} />
                </Box>
            </Grid>

        </Box>
    );
};

export default CateringSection;
