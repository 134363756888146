export const BookNow_EN_trans = {
    title: 'Booking Form',
    required: "Required",
    city: { title: 'Enter City Name', pl: 'Enter City Name', },
    postal_code: { title: "Enter Postal Code", pl: 'Enter Postal Code', },
    address: { title: "Enter Address", pl: 'Enter Address', },
    catering_Type: 'Choose type of Catering',
    diet: 'Do you require diet?',
    date: "Select Date",
    Vali_startDate: "Start date cannot be in the past",
    Vali_endDate: "End date must be after start date",
    contact: "Add contact details",
    full_name: "Enter your full name",
    email: "Enter your email",
    number: "Enter your number",
    person: "Add Persons",
    persons: "Persons",
    budgetPerPerson: "Budget per person",
    total: "Total Budget",
    button: 'Book Now',
    inValid: 'Invalid',
}

export const BookNow_NL_trans = {
    title: 'Boekingsformulier',
    required: "Verplicht",
    city: { title: 'Voer de stadsnaam in', pl: 'Voer de stadsnaam in', },
    postal_code: { title: "Voer de postcode in", pl: 'Voer de postcode in', },
    address: { title: "Voer het adres in", pl: 'Voer het adres in', },
    catering_Type: 'Kies het type catering',
    diet: 'Heeft u dieetwensen?',
    date: "Selecteer een datum",
    Vali_startDate: "Startdatum mag niet in het verleden liggen",
    Vali_endDate: "Einddatum moet na de startdatum liggen",
    contact: "Voeg contactgegevens toe",
    full_name: "Voer uw volledige naam in",
    email: "Voer uw e-mailadres in",
    number: "Voer uw nummer in",
    person: "Voeg personen toe",
    persons: "Personen",
    budgetPerPerson: "Budget per persoon",
    total: "Totale budget",
    button: 'Boek nu',
    inValid: 'Ongeldig',
}
