export const ReachUs_EN_trans = {
    contactUs: "Contact Us",
    fullname: 'Enter full name',
    email: 'Enter email',
    subject: 'Enter subject',
    desc: 'Enter description',
    number: "Enter number",
    btn:"Book Now"
}

export const ReachUs_NL_trans = {
    contactUs: "Neem contact met ons op",
    fullname: "Voer volledige naam in",
    email: "Voer e-mail in",
    subject: "Voer onderwerp in",
    desc: "Voer beschrijving in",
    number: "Voer nummer in",
    btn: "Boek nu"
}



