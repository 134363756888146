import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cards from "./Cards";
import { DataListingComponent } from "../../../Component/DataGridComponent";
import demoImg from "../../../Assets/images/logo.png";
import { useEffect, useState } from "react";
import { API_GET, API_GET2, API_GET_USERID } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const PAGE_SIZE_OPTIONS = [15];
const DashboardTab = () => {
  const { t } = useTranslation();
  const column = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowId",
      headerName: "#",
      width: 50,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile",
      headerName: `${t("ownerProfile.menu.dash.grid.profile")}`,
      width: 100,
      headerAlign: "center",
      renderCell: (cell) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <img
            src={API_endpoints.baseURL + cell?.row?.user_details?.profile_img} // Use the cell value or fallback to demo image
            alt="Profile"
            height={40}
            width={40}
            style={{ borderRadius: '50%' }}
          />
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: `${t("ownerProfile.menu.dash.grid.name")}`,
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cell) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <Typography>
            {cell?.row?.user_details?.full_name}</Typography>
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "from_date",
      headerName: `${t("ownerProfile.menu.dash.grid.booking")}`,
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "to_date",
      headerName: `${t("ownerProfile.menu.dash.grid.eDate")}`,
      width: 150,
      headerAlign: "center",
    },
  ];
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;

  // Sample data to use with the columns
  const initial = [
    {
      id: 1,
      profile: "https://via.placeholder.com/40", // Replace with actual image URL if available
      name: "John Doe",
      bookingDate: "24-24-2024",
      endingDate: "28-24-2024",
    },
    {
      id: 2,
      profile: "https://via.placeholder.com/40",
      name: "John Doe",
      bookingDate: "24-24-2024",
      endingDate: "28-24-2024",
    },
    {
      id: 3,
      profile: "https://via.placeholder.com/40",
      name: "John Doe",
      bookingDate: "24-24-2024",
      endingDate: "28-24-2024",
    },
    {
      id: 4,
      profile: "https://via.placeholder.com/40",
      name: "John Doe",
      bookingDate: "24-24-2024",
      endingDate: "28-24-2024",
    },
  ];

  const [data, setData] = useState(initial);
  const [loading, setloading] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(initial.length);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };


  const getCompanyId = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s)
      {
        await getData(res?.r?.company_details?.id);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const getData = async (id) => {
    try
    {
      let res = await API_GET2(
        `${API_endpoints.getbookingsbyuser}?company_id=${id}&count=0&offset=30`
      );

      if (res?.s)
      {
        const rowId = res?.r?.map((x, i) => {
          return {
            rowId: i + 1,
            ...x,
          };
        });
        setData(rowId);
      }
    }
    catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, [id]);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.menu.dash.note")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} />
      <Box>
        <Cards />
        <Box sx={{ my: 1.5 }}>
          <Typography>{t("ownerProfile.menu.dash.latest")}</Typography>
        </Box>
        <Box>
          <DataListingComponent
            data={data ?? []}
            PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
            loading={IsLoading}
            totalData={totalData}
            page={page}
            Footer={true}
            columns={column}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardTab;
