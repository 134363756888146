import { footer_NL_trans } from "../Component/Footer/Footer_trans";
import { Navbar_NL_Trans } from "../Component/Navbar/Nav_Trans";
import { AboutUS_NL_trans } from "../Pages/AboutUs/About_trans";
import { Login_NL_Trans } from "../Pages/Auth/Login/Login_trans";
import { Register_DE_Trans } from "../Pages/Auth/Register/Register_trans";
import { BecomeAPartner_NL_Trans } from "../Pages/BecomeAPartner/BecomeAPartner_Trans";
import { Faq_NL_trans } from "../Pages/FAQ/FAQ_trans";
import { BookNow_NL_trans } from "../Pages/FoodTruckDetails/BookNow/BookNow_trans";
import { About_NL_trans } from "../Pages/HomePage/HomeTrans/About_trans";
import { CatService_NL_trans } from "../Pages/HomePage/HomeTrans/CatService_trans";
import { Eventandcat_NL_trans } from "../Pages/HomePage/HomeTrans/EvenetAndcat_trans";
import { Explore_NL_Trans } from "../Pages/HomePage/HomeTrans/Explore_trans";
import { Home_NL_Trans } from "../Pages/HomePage/HomeTrans/Home_Trans";
import { Menu_NL_trans } from "../Pages/HomePage/HomeTrans/Menu_trans";
import { Search_Nl_trans } from "../Pages/HomePage/HomeTrans/Search_Trans";
import { Subscription_NL_Trans } from "../Pages/HomePage/HomeTrans/Subscription_trans";
import { Privacy_NL_Trans } from "../Pages/PrivacyPolicy/Privacy_trans";
import { ReachUs_NL_trans } from "../Pages/ReachUs/ReachUs_trans";
import { TandC_NL_Trans } from "../Pages/TermsAndConditions/TermsAndConditions_Trans";
import { OwnerProfile_NL_trans } from "../Pages/TruckOwnerProfile/OwnerProfil_trans";
import { MyBooking_NL_trans } from "../Pages/User/UserTrans/MyBooking_trans";
import { MyProfile_NL_trans } from "../Pages/User/UserTrans/MyProfile_trans";

export const German = {
  translation: {
    Login: Login_NL_Trans,
    Register: Register_DE_Trans,
    Navbar: Navbar_NL_Trans,
    section1: Home_NL_Trans,
    search: Search_Nl_trans,
    explore: Explore_NL_Trans,
    catSer: CatService_NL_trans,
    eAndC: Eventandcat_NL_trans,
    becomePartner: BecomeAPartner_NL_Trans,
    myProfile: MyProfile_NL_trans,
    myBooking: MyBooking_NL_trans,
    footer: footer_NL_trans,
    About: About_NL_trans,
    bookNowForm: BookNow_NL_trans,
    contact: ReachUs_NL_trans,
    faq: Faq_NL_trans,
    about: AboutUS_NL_trans,
    Menu: Menu_NL_trans,
    subscription: Subscription_NL_Trans,
    ownerProfile: OwnerProfile_NL_trans,
    privacy: Privacy_NL_Trans,
    TC: TandC_NL_Trans,
  },
};
