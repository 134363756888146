import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import sideimg1 from "../../../Assets/images/strip1.png";
import sideimg2 from "../../../Assets/images/strip2.png";
import { useTranslation } from "react-i18next";
import EandCCard from "./Cards/eAndCCard";
import Catering from "../../../Assets/images/cate1.png";
import Cat1 from "../../../Assets/images/cat2.jpg";
import Cat2 from "../../../Assets/images/cat3.jpg";
import mainImg from "../../../Assets/images/eandc.webp";
import ButtonComponent from "../../../Component/Button/Button";
import ScrollAnimation from 'react-animate-on-scroll';
const cateringData = [
  {
    id: 1,
    title: "Corporate Catering",
    desc: "Providing professional catering services for corporate events with a variety of menu options.",
    image: Catering,
  },
  {
    id: 2,
    title: "Wedding Catering",
    desc: "Elegant catering services for weddings with customizable menus and themes to suit your special day.",
    image: Cat1,
  },
  {
    id: 3,
    title: "Private Catering",
    desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
    image: Cat2,
  },
  {
    id: 3,
    title: "Private Catering",
    desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
    image: Cat2,
  },
  {
    id: 3,
    title: "Private Catering",
    desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
    image: Cat2,
  },

  {
    id: 3,
    title: "Private Catering",
    desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
    image: Cat2,
  },
];

const EventandcatSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
  

    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        paddingY: { xs: 3, sm: 5 },
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "40%" },
          textAlign: "center",
          m: "auto",
          mb: 4, // Margin to separate from image below
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{
            color: theme.palette.text.dark,
            fontSize: { xs: "28px", sm: "32px", md: "40px" },
            fontWeight: 600,
            mb: 2,
          }}
        >
          {t("eAndC.title")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            mb: 1,
          }}
        >
          {t("eAndC.subTitle")}
        </Typography>
      </Box>

      <ScrollAnimation
        animateIn="animate__fadeIn"
        duration={1}
      >
        <Box
          sx={{
            width: "90%",
            marginX: "auto",
            maxWidth: "1400px",
            px: 2,
          }}
        >
          <Grid container spacing={5} sx={{ alignItems: "flex-start" }}>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "300px", sm: "400px", md: "500px" },
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={mainImg}
                  alt="Event and Catering"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "repeat(auto-fit, minmax(200px, 1fr))", md: "repeat(2, 1fr)" }, 
                  gap: 2,
                }}
              >
                {cateringData.map((x, i) => (
                  <EandCCard data={x} key={i} />
                ))}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{ position: "absolute", right: 0, top: "50px" }}
          >
            <img src={sideimg1} alt="Side 1" height={40} />
          </Box>
          <Box
            sx={{ position: "absolute", left: 0, bottom: "50px" }}
          >
            <img src={sideimg2} alt="Side 2" height={40} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <ButtonComponent
              type="button"
              label={t("eAndC.btn")}
              sx={{
                width: { xs: "80%", sm: "43%", md: "55%", lg: "35%",xl:'20%' },
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
        </Box>
      </ScrollAnimation>
    </Box>
   
  );
};

export default EventandcatSection;
