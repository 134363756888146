import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material";
import { theme } from "../Theme/Theme";
import Login from "../Pages/Auth/Login/Login";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
import HomePage from "../Pages/HomePage/HomePage";
import Register from "../Pages/Auth/Register/Register";
import BecomeAPartnerScreen from "../Pages/BecomeAPartner/BecomeAPartnerScreen";
import { NavRoutes } from "../Component/Navbar/NavRoutes";
import User from "../Pages/User/User";
import AboutUsScreen from "../Pages/AboutUs/AboutUsScreen";
import FoodTruckScreen from "../Pages/FoodTruck/FoodTruckScreen";
import FoodTruckDetailScreen from "../Pages/FoodTruckDetails/FoodTruckDetailScreen";
import ChatScreen from "../Pages/Chat/ChatScreen";
import ReachUsScreen from "../Pages/ReachUs/ReachUsScreen";
import PrivateRoute from "./PrivateRouter";
import NotFoundPageScreen from "../Pages/NotFoundPage/NotFoundPageScreen";
import Faq from "../Pages/FAQ/FAQScreen";
import TruckOwnerProfileScreen from "../Pages/TruckOwnerProfile/TruckOwnerProfileScreen";
import MyFoodTruckScreen from "../Pages/MyFoodTruck/MyFoodTruckScreen";
import PrivacyPolicyScreen from "../Pages/PrivacyPolicy/PrivacyPolicyScreen";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";

const AllRoutes = () => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") ?? "light"
  );

  const {
    palette: { primary, mode, secondary },
  } = useTheme();

  const routes = [
    { path: NavRoutes.PROFILE, component: <User />, isPrivate: true },
    { path: NavRoutes.TRUCKS, component: <FoodTruckScreen />, isPrivate: true },
    { path: NavRoutes.ABOUT, component: <AboutUsScreen />, isPrivate: false },
    { path: NavRoutes.PRIVACY, component: <PrivacyPolicyScreen />, isPrivate: false },
    { path: NavRoutes.TERMS, component: <TermsAndConditions />, isPrivate: false },
    {
      path: NavRoutes.OWNER_PROFILE,
      component: <TruckOwnerProfileScreen />,
      isPrivate: true,
    },
    {
      path: NavRoutes.TRUCK_DETAILS,
      component: <FoodTruckDetailScreen />,
      isPrivate: true,
    },
    { path: NavRoutes.CHAT, component: <ChatScreen />, isPrivate: true },
    {
      path: NavRoutes.MY_FOOD_TRUCK,
      component: <MyFoodTruckScreen />,
      isPrivate: true,
    },
    { path: NavRoutes.REACH, component: <ReachUsScreen /> },
    { path: NavRoutes.LOGIN, component: <Login />, isPrivate: false },
    { path: NavRoutes.REGISTER, component: <Register />, isPrivate: false },
    { path: NavRoutes.HOME, component: <HomePage />, isPrivate: false },
    { path: NavRoutes.FAQ, component: <Faq />, isPrivate: false },
    {
      path: NavRoutes.PARTNER,
      component: <BecomeAPartnerScreen />,
      isPrivate: true,
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme(themeMode)}>
        <Navbar themeMode={themeMode} changeTheme={(e) => setThemeMode(e)} />
        <Routes>
          {routes.map(({ path, component, isPrivate }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ? <PrivateRoute>{component}</PrivateRoute> : component
              }
            />
          ))}
          <Route path="*" element={<NotFoundPageScreen />} />
        </Routes>
        <Footer mode={mode} secondary={secondary} primary={primary} />
      </ThemeProvider>
    </>
  );
};

export default AllRoutes;
