import { Box, Typography,useTheme } from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const CounterSction = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const cards = [
    {
      count: "1451515",
      title: t("about.count.card1"),
    },
    {
      count: "4543",
      title: t("about.count.card2"),
    },
    {
      count: "78435",
      title: t("about.count.card3"),
    },
  ];

  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; 
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; 
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; 
    } else {
      return num.toString(); 
    }
  }
  return (
    <Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "90%" },
          m: "auto",
          py: { xs: 3, md: 5, lg: 7 },
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "80%", md: "50%", lg: "50%" },
            m: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant="h3"
            className="fontSnugle"
            sx={{
              color: theme.palette.text.dark,
              fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
              textAlign: "center",
            }}
          >
            ct title
          </Typography>
          <Typography
            variant="h6"
            // className="fontSnugle"
            sx={{
              color: theme.palette.text.subHead,
              fontSize: { xs: "18px", md: "20px" },
              textAlign: "center",
              mt: "0.5rem",
            }}
          >
            note
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            flexWrap: "wrap",
            gap: 2,
            mt: 3,
          }}
        >
          {cards.map((x, i) => {
            let animationType;
            if (i === 0) {
              animationType = "animate__fadeInLeft";
            } else if (i === 1) {
              animationType = "animate__zoomIn";
            } else if (i === 2) {
              animationType = "animate__fadeInRight";
            }

            return (
              <ScrollAnimation animateIn={animationType} key={i}>
                <Box
                  sx={{
                    background: theme.palette.background.carda,
                    p: 2,
                    borderRadius: 5,
                    borderWidth: "0px 3px 3px 0px",
                    borderStyle: "solid",
                    borderColor: "#F57C004D",
                    width: { xs: "90%", md: "300px", lg: "350px" },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          sm: "33px",
                          md: "40px",
                          xl: "45px",
                        },
                        fontWeight: 700,
                        textAlign: "left",
                        color: theme.palette.text.active,
                      }}
                    >
                      {formatNumber(x.count)}+
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "16px",
                          md: "20px",
                          xl: "22px",
                        },
                        textAlign: "left",
                        fontWeight: "500",
                      }}
                    >
                      {x.title}
                    </Typography>
                  </Box>
                </Box>
              </ScrollAnimation>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CounterSction;
