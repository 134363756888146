import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataListingComponent } from "../../../Component/DataGridComponent";
import FoodTruckDetailsModal from "./MyBookingsModal";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { API_GET2, API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { toast } from "react-toastify";
let PAGE_SIZE_OPTIONS = [15];

const MyBookingTab = () => {
  const { t } = useTranslation();
  const [SelectedBookingId, setSelectedBookingId] = useState(0);

  const column = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowId",
      headerName: "#",
      width: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile",
      headerName: `${t("ownerProfile.menu.booking.grid.Profile")}`,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {/* Render profile image */}
          <img
            src={API_endpoints.baseURL + params?.row?.user_details?.profile_img}
            alt="Profile"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%", // Optional: display as circle
              objectFit: "cover",
            }}
          />
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "userName",
      headerName: `${t("ownerProfile.menu.booking.grid.name")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.user_details?.full_name;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "bookingDate",
      headerName: `${t("ownerProfile.menu.booking.grid.booking")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.from_date;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "endingDate",
      headerName: `${t("ownerProfile.menu.booking.grid.eDate")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.to_date;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "booking_status",
      headerName: `${t("ownerProfile.menu.booking.grid.action")}`,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        const { row: { booking_status } } = cell
        return booking_status === 0 ? <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
            }}
          >
            <CheckRoundedIcon
              sx={{
                "&:hover": {
                  color: "green",
                  transform: "scale(1.2)",
                  transition: "all 0.1s linear",
                },
              }}
              onClick={() =>
                handleAction(
                  1,
                  cell?.row?.id,
                  cell?.row?.from_date,
                  cell?.row?.to_date
                )
              }
            />
            <CloseRoundedIcon
              sx={{
                "&:hover": {
                  color: "red",
                  transform: "scale(1.2)",
                  transition: "all 0.1s linear",
                },
              }}
              onClick={() => handleAction(0, cell?.row?.id)}
            />
          </Box>
        </Box> : booking_status === 1 ? <span style={{ color: "green" }}>Approved</span> : <span style={{ color: "red" }}>Rejected</span>
      }


    },
    {
      headerClassName: "super-app-theme--header",
      field: "action",
      headerName: `${t("ownerProfile.menu.booking.grid.view")}`,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          variant="contained"
          color="primary"
          sx={{
            "&:hover": {
              color: "#F57C00",
              textDecoration: "underline",
              transition: "all 0.1s linear",
            },
          }}
          onClick={() => {
            setSelectedBookingId(params?.row?.id);
            setOpen(true);
          }}
        >
          {t("ownerProfile.menu.booking.grid.ViewDetail")}
        </Box>
      ),
    },
  ];

  const [data, setData] = useState([]);

  const [IsLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(data?.length);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [id, setID] = useState("");
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const parsedData = JSON.parse(localStorage.getItem("Fiesta"));
  let user_id = parsedData?.id;

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const getCompanyId = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, user_id);
      if (res.s)
      {
        setID(res?.r?.company_details?.id);
        await getData(res?.r?.company_details?.id);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const getData = async (id) => {
    try
    {
      let res = await API_GET2(
        `${API_endpoints.getbookingsbyuser}?company_id=${id}&count=0&offset=30`
      );
      if (res?.s)
      {
        const rowId = res?.r?.map((x, i) => {
          return {
            rowId: i + 1,
            ...x,
          };
        });
        setData(rowId);
        setTotalData(rowId?.length);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleAction = async (status, booking_id, From_Date, End_Date) => {
    try
    {
      if (status === 1)
      {
        const d = new FormData();
        d.append("company_id", id);
        d.append("from_date", From_Date);
        d.append("to_date", End_Date);
        const res = await API_POST(API_endpoints.checkAvailability, d);
        if (res?.s === 1)
        {
          const res = await API_POST(API_endpoints.BookingAction + "/approve", {
            booking_id,
          });
          if (res?.s)
          {
            toast.success(`Approved successfully`);
          } else
          {
            toast.warn(res?.m);
          }
        }
      } else
      {
        const res = await API_POST(API_endpoints.BookingAction + "/cancel", {
          booking_id,
        });
        if (res?.s)
        {
          toast.success(`Rejected successfully`);
        }
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, [id]);

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.menu.booking.note")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} />
      <Box sx={{ minHeight: "300px" }}>
        <DataListingComponent
          data={data ?? []}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={IsLoading}
          totalData={totalData}
          page={page}
          Footer={true}
          hidefooter={true}
          columns={column}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
        <FoodTruckDetailsModal
          open={open}
          setOpen={setOpen}
          SelectedBookingId={SelectedBookingId}
        />
      </Box>
    </Box>
  );
};

export default MyBookingTab;
