export const Subscription_EN_Trans = {
    Headline: "Subscription Details",

    SubHeadline:
        "It is a long established fact that a reader will be distracted by the readable",
    plan: "Plan",
    desc: "Description",
    sDate: "Start Date",
    eDate: "End Date",
};
export const Subscription_NL_Trans = {
    Headline: "Abonnementsgegevens",

    SubHeadline:
        "Het is een bekend gegeven dat een lezer wordt afgeleid door de leesbare inhoud.",
    plan: "Plan",
    desc: "Beschrijving",
    sDate: "Startdatum",
    eDate: "Einddatum",
};

