export const Navbar_EN_Trans = {
  Home: "Home",
  BecomeAPartner: "Become A Partner",
  FoodTrucks: "Food Trucks",
  AboutUs: "About Us",
  Profile: "Profile",
  ReachUs: "Reach us",
  Login: "Login",
  chat: "Chat",
  Logout: "Logout",
  mytrucks: "My Trucks",
};

export const Navbar_NL_Trans = {
  Home: "Home",
  BecomeAPartner: "Word een partner",
  FoodTrucks: "Foodtrucks",
  AboutUs: "Over ons",
  Profile: "Profiel",
  ReachUs: "Contacteer ons",
  Login: "Inloggen",
  chat: "Chatten",
  Logout: "Uitloggen",
  mytrucks: "mijn vrachtwagens",
};
