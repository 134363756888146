import React, { useEffect } from "react";
import HomeSection from "./Sections/HomeSection";
import SearchSection from "./Sections/SearchSection";
import ExploreSection from "./Sections/ExploreSection";
import CateringSection from "./Sections/CateringSection";
import MenuSection from "./Sections/MenuSection";
import AboutSection from "./Sections/AboutSection";
import CateringServiceSection from "./Sections/CateringServicesSection";
import EventandcatSection from "./Sections/EventandcatSection";
import Subscription from "./Sections/Subscription";


const HomePage = () => {
  const userData = JSON.parse(localStorage.getItem('Fiesta'))
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    <>
      <HomeSection />
      <SearchSection />
      <ExploreSection />
      {userData && userData.role === 2 && <Subscription />}
      <CateringSection />
      <CateringServiceSection />
      <MenuSection />
      <AboutSection />
      <EventandcatSection />
    </>
  );
};

export default HomePage;
