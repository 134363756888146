export const Explore_En_trans = {
    title: "Explore the Food Truck",
    subtitle: "It is a long established fact that a reader will be distracted by the readable",
    button:'Book Now',
    edit:'Edit'
}

export const Explore_NL_Trans = {
    title: "Ontdek de Foodtruck",
    subtitle: "Het is een bekend gegeven dat een lezer wordt afgeleid door de leesbare inhoud.",
    button: "Boek Nu",
    edit: "Bewerken"
};

