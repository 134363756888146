import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ButtonComponent from "../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import SingleImage from "../../Component/ImageUploader/SingleImage";
import { API_endpoints } from "../../API/API_Endpoints";
import { API_GET, API_GET_USERID, API_POST } from "../../API/config";
import { useFormik } from "formik";
import CustomPhoneNumber from "../../Component/Input/PhoneInput";
import styled from "@emotion/styled/macro";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Component/Modal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import ForgotPasswordDialogue from "../Auth/Login/ForgotPassword";
import Loader from "../../Component/Loader/Loader";

const MyProfile = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const BaseUrl = API_endpoints?.baseURL;
  const InputWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    padding: "18px",
    borderRadius: "12px",
    border:
      formik.touched.dob && formik.errors.dob
        ? "1px solid red"
        : "1px solid #1A130C3D",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      border:
        formik.touched.dob && formik.errors.dob
          ? "1px solid red"
          : `1px solid ${theme.palette.background.lightorange}`,
      backgroundColor: theme.palette.background.inputBg,
    },
    '& input[type="date"]': {
      width: "100%",
      border: "none",
      backgroundColor: "transparent",
      color: theme.palette.text.defaultDark,
      padding: "0",
      margin: "0",
      "&:focus": {
        outline: "none",
      },
    },
  }));
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();

    const event = new CustomEvent("authUpdate", {
      detail: { isLoggedIn: false },
    });
    window.dispatchEvent(event);
    toast.success("Logout Successfully");
    navigate("/");
  };

  const handleCountry = async () => {
    try
    {
      let res = await API_GET(API_endpoints.countries);
      setCountries(res.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleUserData = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      formik.setValues({
        fullname: res.r?.full_name || "",
        dob: res.r?.dob || "",
        cc: res.r?.phno_cc || "",
        phno: res.r?.phno || "",
        profile: res.r?.profile_img || null,
        country: res.r?.country_id || 0,
      });
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
    handleCountry();
  }, []);

  const formik = useFormik({
    initialValues: {
      profile: null,
      country: 0,
      fullname: "",
      dob: "",
      cc: "",
      phno: "",
    },
    validationSchema: Yup.object({
      profile: Yup.string().required("Profile Image is required"),
      country: Yup.string()
        .required("Country is required")
        .notOneOf(["0"], "Please select a valid country."),
      fullname: Yup.string().required("Full name is required"),
      dob: Yup.date().required("Date of birth is required").nullable(),
      phno: Yup.string()
        .required("Number is required")
        .matches(/^[0-9]+$/, "Only numbers are allowed"),
    }),
    onSubmit: async (values) => {
      const f = new FormData();
      f.append("full_name", formik.values.fullname);
      f.append("country_id", formik.values.country);
      f.append("profile_img", formik.values.profile);
      f.append("dob", formik.values.dob);
      f.append("email", formik.values.email);
      f.append("phno_cc", "+91");
      f.append("phno", formik.values.phno);
      f.append("password", formik.values.password);
      f.append("user_id", id);

      try
      {
        const res = await API_POST(API_endpoints.updateuser, f);
        if (res.s)
        {
          toast.success(res.m);
          handleUserData();
        } else
        {
          toast.error(res.m);
        }
      } catch (error)
      {
        console.error("Error:", error);
      }
    },
  });
  return (
    <Box sx={{ width: "90%", m: "auto", maxWidth: "1400px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          py: { xs: 3, md: 5 },
          gap: 1,
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "24px", md: "30px" },
          }}
        >
          {`${t("myProfile.title")}`}
        </Typography>
        <ButtonComponent
          onClick={() => {
            setOpen(true);
          }}
          label={`${t("myProfile.logout")}`}
          sx={{ border: `1px solid ${theme.palette.background.btn}` }}
        />
      </Box>
      <Box
        className={loading ? "flexCenter" : ""}
        sx={{
          bgcolor: "#fff",
          borderRadius: "20px",
          minHeight: "300px",
          p: { xs: 2, md: 5 },
        }}
      >
        <Box>
          {loading ? (
            <Box>
              <Loader />
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  m: "auto",
                  width: "90px",
                  height: "90px",
                  cursor: "pointer",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SingleImage
                  apiPreview={
                    typeof formik.values.profile === "string"
                      ? BaseUrl + formik.values.profile
                      : null
                  }
                  setImage={formik.setFieldValue}
                  name="profile"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1,
                  mt: 1,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    type="text"
                    id="fullname"
                    name="fullname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullname}
                    error={
                      formik.touched.fullname && Boolean(formik.errors.fullname)
                    }
                    helperText={
                      formik.touched.fullname && formik.errors.fullname
                    }
                    variant="outlined"
                    placeholder={t("Register.fullname")}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
                <Box width={"100%"}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    sx={{
                      color: theme.palette.text.defaultDark,
                      textAlign: "start",
                      borderRadius: "12px",
                    }}
                  >
                    <Select
                      fullWidth
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        color: theme.palette.text.select_input,
                        borderRadius: "12px",
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                        "&:active": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                      }}
                    >
                      <MenuItem
                        style={{
                          display: "none",
                          color: theme.palette.text.defaultDark,
                        }}
                        value="0"
                      >
                        {t("Register.selectcountry")}
                      </MenuItem>
                      {Array.isArray(countries) &&
                        countries.length > 0 &&
                        countries.map((e, i) => (
                          <MenuItem
                            style={{ color: theme.palette.text.defaultDark }}
                            key={i}
                            value={e?.id || "0"} // Use fallback value for safety
                          >
                            {e?.country_name || "Unknown Country"}
                          </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.country && formik.errors.country && (
                      <FormHelperText>{formik.errors.country}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1,
                  mt: 1,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <InputWrapper>
                    <input
                      type="date"
                      value={formik.values.dob}
                      onChange={(e) =>
                        formik.setFieldValue("dob", e.target.value)
                      }
                      onBlur={formik.handleBlur} // Handle blur to trigger validation
                    />
                  </InputWrapper>{" "}
                  {formik.touched.dob && formik.errors.dob && (
                    <div
                      style={{
                        color: theme.palette.text.error,
                        fontSize: "12px",
                        textAlign: "start",
                      }}
                    >
                      {formik.errors.dob}
                    </div>
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <CustomPhoneNumber
                    name="phno"
                    theme={theme}
                    country={"us"}
                    placeholder="Enter your Number"
                    value={formik.values.cc + formik.values.phno}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phon_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      formik.setFieldValue("phno", phno);
                      formik.setFieldValue("cc", phon_cc);
                    }}
                    inputClass="inputClassCountry"
                  />

                  {formik.touched.phno && formik.errors.phno && (
                    <div style={{ color: "red", marginTop: "4px" }}>
                      {formik.errors.phno}
                    </div>
                  )}
                </Box>
              </Box>
              <Box>
                {/* <Typography
            sx={{
              color: theme.palette.text.active,
              mt: 1,
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
            }}
          >{`${t("myProfile.reset")}`}</Typography> */}
                <Box sx={{ mt: 1 }}>
                  <ForgotPasswordDialogue title={t("myProfile.reset")} />
                </Box>
              </Box>
              <Box sx={{ mt: 1 }} className="flexCenter">
                <ButtonComponent
                  type="button"
                  onClick={formik.handleSubmit}
                  label={`${t("myProfile.update")}`}
                  sx={{
                    color: theme.palette.text.primary,
                    bgcolor: theme.palette.background.btn,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmationModal
        // btnImg={}
        open={open}
        setOpen={setOpen}
        handleClick={logout}
        cnfBtnTitle={"Logout"}
      // status={""}
      />
    </Box>
  );
};

export default MyProfile;
