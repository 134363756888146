import { Button } from "@mui/material";
import React from "react";

const ButtonComponent = ({ label, type, sx, onClick, height, fontfamily, disabled }) => {
  return (

    <Button
      className={fontfamily || "fontSnugle"}
      onClick={onClick}
      disabled={disabled || false}
      type={type || "button"}
      sx={{
        ...sx,
        position: 'relative',
        borderRadius: "40px",
        height: "48px",
        padding: "4px 38px",
        textTransform: "none",
        fontSize: "18px",
        overflow: "hidden",
        zIndex: 1,
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '50%',
          height: '100px',
          top: -10,
          left: -10,
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          transition: 'transform 0.6s ease',
          zIndex: -1,
          transform: 'translateX(-100%) rotate(10deg)',
        },
        '&:hover::before': {
          transform: 'translateX(350%)',
        },
      }}
    >
      {label}
    </Button>


  );
};

export default ButtonComponent;
