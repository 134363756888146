import BannerImage from "../../Component/BannerImage/BannerImage";
import { Box, Grid, useTheme, Typography } from "@mui/material";
import TruckCard from "../HomePage/Sections/Cards/TruckCard";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_GET_OPEN, API_GET_USERID } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import Noresult from "../../Component/Noresultfound/Noresult";
import { useTranslation } from "react-i18next";
import FoodTruckEditModal from "../FoodTruckEdit/FoodTruckEditModal";

const MyFoodTruckScreen = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const [compID, setCompID] = useState("");
  const [truckid, setTruckid] = useState("");
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  useEffect(() => {
    if (location.state && location.state.data) {
      setData(location.state.data);
    }
  }, [location.state]);

  const getData = async (id) => {
    try {
      let res = await API_GET_OPEN(
        `${API_endpoints.getalltrucks}?company_id=${id}`
      );
      setData(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyId = async () => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s) {
        getData(res?.r?.company_details?.id);
        setCompID(res?.r?.company_details?.id)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = async (id) => {
    setOpen(true);
    setTruckid(id);
  };

  useEffect(() => {
    getCompanyId();
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  
  return (
    <Box>
      {/* Banner Section */}
      <BannerImage path={"My Food Trucks"} />
      <Box
        sx={{
          background: theme.palette.background.gd,
          py: 4,
        }}
      >
        <Box
          sx={{
            width: "90%",
            maxWidth: "1440px",
            m: "auto",
          }}
        >
          <Box sx={{ pb: 2 }}>
            <Grid container spacing={4} sx={{ justifyContent: "center" }}>
              {data?.length ? (
                data?.map((x, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      key={i}
                      sx={{
                        transition: "transform 0.3s ease-in-out",
                      }}
                    >
                      <TruckCard
                        data={x}
                        title={t("Edit")}
                        openModal={openModal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box>
                  <Typography
                    className="fontSnugle"
                    sx={{ textAlign: "center", fontSize: "22px" }}
                  >
                    No Record Found
                  </Typography>
                  <Noresult />
                </Box>
              )}
            </Grid>
          </Box>
          <FoodTruckEditModal open={open} setOpen={setOpen} id={truckid} getData={getData} compID={compID} />
        </Box>
      </Box>
    </Box>
    // <Work/>
  );
};

export default MyFoodTruckScreen;
