import { Box, Button, TextField, useTheme } from "@mui/material";
import FloatActionButton from "./FloatingActionButton";
import { useTranslation } from "react-i18next";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';

const ChatFooter = ({ handleChange, typedMessage }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1.5,
            }}
        >
            {/* <FloatActionButton /> */}
            <Box sx={{}}>
                <span style={{ background: '#DEE6F2', padding: '15px', borderRadius: '50%', }} className="flexCenter" onClick={() => console.log('Send Message')
                }>
                    <AddIcon sx={{ color: "#000" }} />
                </span>
            </Box>
            <TextField
                fullWidth
                type="text"
                id="fullname"
                name="fullname"
                onChange={handleChange}
                value={typedMessage}
                variant="outlined"
                placeholder={t("Type message here...")}
                InputProps={{
                    sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                        },
                    },
                }}
                sx={{
                    width: "calc(100% - 150px)",
                }}
            />
            <Box sx={{}}>
                <span style={{ background: theme.palette.background.orange, padding: '15px', borderRadius: '50%', }} className="flexCenter" onClick={() => console.log('Send Message')
                }>
                    <SendIcon sx={{ rotate: '-40deg', color: "white" }} />
                </span>
            </Box>
        </Box>
    );
};

export default ChatFooter;
