import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyDetailsTab from "./OwnerCompanyTabs/OwnerCompanyDetailsTab";
import OwnerCatererTab from "./OwnerCompanyTabs/OwnerCatererTab";
import OwnerMealsAndDietTab from "./OwnerCompanyTabs/OwnerMealsAndDietTab";
import OwnerImageTab from "./OwnerCompanyTabs/OwnerImageTab";

const MyCompanyTab = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [CurrentOwnerTab, setCurrentOwnerTab] = useState(0);
    const tabList = [
        {
            id: 0,
            // label: t("ownerProfile.menu.dash.title"),
            label: t("Company Details"),
            data: <CompanyDetailsTab />,
        },
        {
            id: 1,
            label: t("Caterers Type"),
            data: <OwnerCatererTab />,
        },
        {
            id: 2,
            label: t("Meals & Diet"),
            data: <OwnerMealsAndDietTab />,
        },
        {
            id: 3,
            label: t("Images"),
            data: <OwnerImageTab />,
        },
    ];

    return (
        <Box>
            {/* <Box sx={{ border: "1px solid #1A130C28", my: 2 }} /> */}
            <Box>
                {/* tab head */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: { xs: 3, md: 5 },
                        // py: { xs: 2, md: 3 },
                        // px: 2,
                        overflow: "auto",
                    }}
                >
                    {tabList.map((e, i) => (
                        <Box
                            key={i}
                            className="Bluelink"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: e.id === CurrentOwnerTab && theme.palette.text.blue,
                                fontWeight: e.id === CurrentOwnerTab && 600,
                                fontSize: { xs: "18px", md: "20px" },
                                cursor: "pointer",
                                textWrap: "nowrap",
                                "&:hover": {
                                    color: theme.palette.text.blue,
                                },
                            }}
                            onClick={() => setCurrentOwnerTab(i)}
                        >
                            {e?.label}
                        </Box>
                    ))}
                </Box>
                <Box sx={{ border: "1px solid #1A130C28", my: 1 }} />
                {/* tab data */}
                <Box sx={{ pb: 5 }}>
                    <Box
                        sx={{
                            background: theme.palette.background.main,
                            width: "100%",
                            m: "auto",
                            borderRadius: "35px",
                            position: "relative",
                            py: 2,
                        }}
                    >
                        {tabList[CurrentOwnerTab]?.data}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default MyCompanyTab;
